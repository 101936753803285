import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

// import { useHttpClient } from '../../shared/hooks/http-hook';
// import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  editBtn: {
    color: 'gray',
    margin: '-10px 0'
  },
  viewBtn: {
    margin: '-10px 0'
  },
  btnLink: {
    textDecoration: 'none'
  }
}));

const statusColors = {
  pastDue: 'red',
  waiting: 'grey',
  pending: 'blue',
  complete: '#15af15'
};

const AssessmentsIndexItem = props => {
  const classes = useStyles();

  const camelCaseToTitleCase = str =>
    str.charAt(0).toUpperCase() +
    str
      .substr(1)
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .trim();


  return (
    <TableRow key={props.trackingId}>
      <TableCell component="th" scope="row">
        {props.trackingId}
      </TableCell>
      <TableCell align="right">
        {moment(props.scheduled).format('M/DD/YY')}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        <span style={{ color: statusColors[props.assessmentStatus] }}>
          {camelCaseToTitleCase(props.assessmentStatus)}
        </span>
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {moment(props.updated).format('M/DD/YY')}
      </TableCell>
      <TableCell align="right">{props.clientName}</TableCell>
      <TableCell align="right">{props.clientEmail}</TableCell>
      <TableCell align="right">{camelCaseToTitleCase(props.outcome)}</TableCell>
      {/* <TableCell align="center">
        <Button
          className={classes.viewBtn}
          color="primary"
          size="small"
          startIcon={<VisibilityIcon />}
          fullWidth
        >
          Request edit
        </Button>
      </TableCell> */}
      <TableCell align="center">
        <Link
          target="_blank"
          className={classes.btnLink}
          to={`/assessments/view/${props.id}`}
        >
          <Button
            className={classes.viewBtn}
            color="primary"
            size="small"
            startIcon={<VisibilityIcon />}
            fullWidth
          >
            View
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default AssessmentsIndexItem;
