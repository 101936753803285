import React from 'react';

import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import Asterisk from '../layout/RequiredAsterisk';


const useStyles = makeStyles(theme => ({
  radioContainer: {
    margin: theme.spacing(3, 0, 1, 0)
  },
  formControl: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  label: {
    marginLeft: 0
  }
}));

const AssessmentRadio = (props) => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const handleChange = evt => update(evt.target.name, evt.target.value);

  return (
    <div className={classes.radioContainer}>
      <Typography variant="h6">
        {question.text} {!question.optional && <Asterisk />}
      </Typography>
      {question.subtext && (
        <Typography variant="subtitle1">{question.subtext}</Typography>
      )}

      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label={question.id}
          name={question.id}
          value={answer}
          onChange={handleChange}
          // row
        >
          {question.options.map(option => {
            if (option.deprecated && answer !== option.id) {
              return null;
            }
            return (
              <FormControlLabel
                key={option.id}
                className={classes.label}
                value={option.id}
                control={<Radio color="primary" />}
                label={option.text}
                labelPlacement="end"
              />
            );
          })}
        </RadioGroup>
        {/* <FormHelperText>labelPlacement start</FormHelperText> */}
      </FormControl>
    </div>
  );
};

export default AssessmentRadio;
