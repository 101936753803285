import React from 'react';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import AssessmentConfig from '../data/AssessmentConfig';
import normalizeConfig from '../data/config-helpers';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  answer: {
    margin: theme.spacing(1, 0, 1, 0)
  }
}));

const AssessmentsViewContent = props => {
  const { assessment } = props;
  const conf = normalizeConfig(AssessmentConfig);
  const classes = useStyles();

  const questions = conf.getQuestions();

  const answers =
    assessment.answers &&
    Object.entries(assessment.answers).map(entry => {
      const [questionId, answer] = entry;
      const question = questions.find(q => q.id === questionId);
      const answerText = {
        checkbox: () => (answer ? 'Yes' : 'No'),
        radio: () => {
          const option =
            question.options && question.options.find(o => o.id === answer);
          return option.deprecated
            ? option.text + ' (DEPRECATED OPTION)'
            : option.text;
        },
        select: () => {
          const option =
            question.options && question.options.find(o => o.id === answer);
          return option.deprecated
            ? option.text + ' (DEPRECATED OPTION)'
            : option.text;
        },
        rating: () => String(answer),
        text: () => answer,
        textarea: () => answer,
        datetime: () => moment(answer).format('YYYY-MM-DD'),
        date: () => moment(answer).format('YYYY-MM-DD'),
        time: () => moment(answer).format('YYYY-MM-DD')
      }[question.type]();

      return (
        <div key={questionId} className={classes.answer}>
          <Typography variant="body1">
            {question.text}: <strong>{answerText}</strong>
          </Typography>
        </div>
      );
    });

  return (
    <React.Fragment>
      {answers || 'No answers found.'}
    </React.Fragment>
  );
};

export default AssessmentsViewContent;
