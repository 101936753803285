import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles(theme => ({
  text: {
    margin: theme.spacing(3, 0),
    width: '450px'
  }
}));

const isValidISODate = d => moment(d, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();

const normalizeStr = str => str.replace(/\s/g, '').toLowerCase();

const applySearch = (arr, searchable, search) => {
  return arr.filter(obj => {
    const empty = search === '';
    const matches = searchable.some(key => {
      const value = obj[key];
      const formatted =
        typeof value === 'string' && isValidISODate(value)
          ? moment(value).format('M/DD/YY')
          : String(value);
      return normalizeStr(formatted).includes(normalizeStr(search));
    });
    return empty || matches;
  });
};

const Search = props => {
  const { search, setSearch } = props;
  const classes = useStyles();

  const handleChange = evt => setSearch(evt.target.value);

  return (
    <TextField
      className={classes.text}
      value={search}
      onChange={handleChange}
      label="Search..."
      variant="outlined"
      // helperText={'Search ' + searchable.join(', ') + '.'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export { Search, applySearch };
