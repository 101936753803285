import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Asterisk from '../layout/RequiredAsterisk';


const useStyles = makeStyles(theme => ({
  dateTimeContainer: {
    margin: theme.spacing(3, 0, 1, 0)
  },
  formControl: {
    marginTop: theme.spacing(1)
  }
}));

const AssessmentDateTime = props => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const handleDateChange = momentDate => update(question.id, momentDate.toDate());

  return (
    <div className={classes.dateTimeContainer}>
      <Typography variant="h6">
        {question.text} {!question.optional && <Asterisk />}
      </Typography>
      {question.subtext && (
        <Typography variant="subtitle1">{question.subtext}</Typography>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className={classes.formControl}
          margin="normal"
          id={question.id + '-date'}
          label={question.label ? question.label[1] : question.text + ' Date'}
          // format="MM/DD/YYYY"
          value={answer}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
        <KeyboardTimePicker
          className={classes.formControl}
          margin="normal"
          id={question.id + '-time'}
          label={question.label ? question.label[1] : question.text + ' Time'}
          value={answer}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default AssessmentDateTime;