import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const AssessmentDate = (props) => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const changeHandler = evt => update(question.id, evt.target.value);

  return (
    <div>
      <Typography>
        {question.text}
      </Typography>
      <TextField
        id="date"
        label={question.id}
        type="date"
        defaultValue={answer}
        onChange={changeHandler}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
      />
    </div>
  );
};

export default AssessmentDate;
