import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import AssessmentsHeading from '../components/AssessmentsHeading';
import AssessmentsEditContent from '../components/AssessmentsEditContent';
import ErrorDialog from '../../shared/components/ErrorDialog';
import { useHttpClient } from '../../shared/hooks/http-hook';

const AssessmentsEdit = () => {
  const [loadedAssessment, setLoadedAssessment] = useState();
  const [hasGoogleDriveLink, setHasGoogleDriveLink] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const assessmentId = useParams().assessmentId;

  useEffect(() => {
    const fetchAssessment = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/assessments/${assessmentId}`;
        const { assessment } = await sendRequest(url);
        setLoadedAssessment(assessment);
        // Google Drive link must be evaluated at page-level so that we can re-query db after it is set
        setHasGoogleDriveLink(!!assessment.booking.clientGoogleDriveLink);
      } catch (err) {}
    };
    fetchAssessment();
  }, [sendRequest, assessmentId, hasGoogleDriveLink]);

  return (
    <section>
      <ErrorDialog error={error} open={!!error} onClose={clearError} />
      {isLoading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!isLoading && loadedAssessment && (
        <React.Fragment>
          <AssessmentsHeading
            hasGoogleDriveLink={hasGoogleDriveLink}
            setHasGoogleDriveLink={setHasGoogleDriveLink}
            assessment={loadedAssessment}
          />
          <AssessmentsEditContent assessment={loadedAssessment} />
        </React.Fragment>
      )}
    </section>
  );
};

export default AssessmentsEdit;
