import React from 'react';

import AlertDialog from './AlertDialog';
// import Button from '../FormElements/Button';

const ErrorDialog = ({ error, open, onClose }) => (
  <AlertDialog
    title="An error occurred."
    content={error}
    open={open}
    onClose={onClose}
  />
);


export default ErrorDialog;
