import React, { useContext } from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { AuthContext } from '../../shared/context/auth-context';
import GoogleDriveForm from './layout/GoogleDriveForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  popoverText: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'inline-block'
  }
}));

const MouseOverPopover = props => {
  const { textAnchor, body } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.popoverText}
      >
        {textAnchor}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {body}
      </Popover>
    </div>
  );
}

const camelCaseToTitleCase = str =>
  str.charAt(0).toUpperCase() +
  str
    .substr(1)
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1')
    .trim();

const BasicLink = props => (
  <a target="_blank" rel="noopener noreferrer" href={props.href}>
    {props.children}
  </a>
);



const ClientHistory = props => {
  const { assessment, index } = props;
  const coachName = assessment.booking.coachName;
  const date = moment(assessment.booking.scheduled).format('M/DD/YY');
  const outcome = (function() {
    const answers = assessment.answers;
    const outcome = answers && answers.outcome;
    const scheduledCall = answers && answers.scheduledCall;
    return outcome || scheduledCall || '';
  })();
  const goToLink = `/assessments/view/${assessment.id}`;
  return (
    <div>
      {index !== 0 && <hr style={{ margin: '0' }} />}
      <Typography variant="body2" style={{ padding: '14px 18px' }}>
        Date: <strong>{date}</strong>
        <br />
        Coach: <strong>{coachName}</strong>
        <br />
        Outcome: <strong>{camelCaseToTitleCase(outcome)}</strong>
        <br />
        View <BasicLink href={goToLink}>assessment</BasicLink>
      </Typography>
    </div>
  );
}

const getClientHistory = assessments => {
  return assessments.map((assessment, i) => (
    <ClientHistory key={i} assessment={assessment} index={i} />
  ));
};

const AssessmentsHeading = (props) => {
  const { assessment, hasGoogleDriveLink, setHasGoogleDriveLink } = props;
  const { booking } = assessment;
  const {
    trackingId,
    clientName,
    clientEmail,
    clientPhone,
    clientGrossAnnualIncome,
    clientGoogleDriveLink,
    infusionsoftId,
    scheduled
  } = booking;
  const auth = useContext(AuthContext);

  const infusionsoftLink = (function () {
    const infBaseUrl = 'https://cq705.infusionsoft.com/';
    const encodedEmail = window.encodeURIComponent(clientEmail);
    return booking.infusionsoftId
      ? `${infBaseUrl}Contact/manageContact.jsp?view=edit&ID=${infusionsoftId}&Sidebar=true`
      : `${infBaseUrl}app/searchResults/searchResults?searchTerm=${encodedEmail}`;
  }());
  const scheduledDate = moment(scheduled).format('M/DD/YY');


  const clientHistory =
    Array.isArray(assessment.related) && assessment.related.length ? (
      <MouseOverPopover
        textAnchor="Client's History"
        body={getClientHistory(assessment.related)}
      />
    ) : null;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Box style={{ marginBottom: '2rem' }}>
            <Typography variant="subtitle1">
              Assessment for booking id# <strong>{trackingId}</strong> on{' '}
              <strong>{scheduledDate}</strong>
            </Typography>
            <Typography variant="h5">
              <BasicLink href={infusionsoftLink}>{clientName}</BasicLink>
            </Typography>
            <Typography variant="subtitle1">{clientEmail}</Typography>
            <Typography variant="subtitle2">{clientPhone}</Typography>
            <Typography variant="subtitle1">
              Income range: {clientGrossAnnualIncome || '(empty)'}
            </Typography>
            {clientHistory}
            {hasGoogleDriveLink ? (
              <Typography variant="subtitle1">
                <BasicLink href={clientGoogleDriveLink}>
                  Client's Google Drive Folder
                </BasicLink>
              </Typography>
            ) : (
              <GoogleDriveForm
                setHasGoogleDriveLink={setHasGoogleDriveLink}
                booking={booking}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          {auth.bookingPageUrl && (
            <Typography variant="h6">
              <BasicLink href={auth.bookingPageUrl}>
                My Direct ScheduleOnce Page
              </BasicLink>
            </Typography>
          )}
        </Grid>
      </Grid>
      <hr />
    </>
  );
};

export default AssessmentsHeading;