import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';



import Avatar from '@material-ui/core/Avatar';

import CircularProgress from '@material-ui/core/CircularProgress';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorDialog from '../../shared/components/ErrorDialog';
import EditorUsersEditForm from '../components/EditorUsersEditForm';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

const EditorUsersEdit = () => {
  const classes = useStyles();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userId } = useParams();
  const [loadedUser, setLoadedUser] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`;
        const { user } = await sendRequest(url);
        setLoadedUser(user);
      } catch (err) {}
    };
    fetchUser();
  }, [sendRequest, userId]);


  return (
    <section>
      <Container maxWidth="md">
        <Link to="/editor/users">Back to Users index</Link>
      </Container>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <ErrorDialog error={error} open={!!error} onClose={clearError} />
          <Avatar className={classes.avatar}>
            <EditIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update {loadedUser ? `"${loadedUser.name}"` : 'User'}
          </Typography>
          {isLoading && (
            <div className="center">
              <CircularProgress />
            </div>
          )}
          {!isLoading && loadedUser && (
            <EditorUsersEditForm user={loadedUser} sendRequest={sendRequest} />
          )}
        </div>
      </Container>
    </section>
  );
};

export default EditorUsersEdit;
