import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';


import { useHttpClient } from '../../shared/hooks/http-hook';
// import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  editBtn: {
    color: 'gray',
    margin: '-10px 0'
  },
  viewBtn: {
    margin: '-10px 0'
  },
  btnLink: {
    textDecoration: 'none'
  }
}));

const statusColors = {
  pastDue: 'red',
  waiting: 'grey',
  pending: 'blue',
  complete: '#15af15'
};



const EditorAssessmentsIndexItem = props => {
  const { auth, forceUpdate } = props;
  const { sendRequest } = useHttpClient();
  const classes = useStyles();

  const camelCaseToTitleCase = str =>
    str.charAt(0).toUpperCase() +
    str
      .substr(1)
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .trim();

  const infBaseUrl = 'https://cq705.infusionsoft.com/';
  const clientEmail = window.encodeURIComponent(props.clientEmail);
  const infusionsoftLink = props.infusionsoftId
    ? `${infBaseUrl}Contact/manageContact.jsp?view=edit&ID=${props.infusionsoftId}&Sidebar=true`
    : `${infBaseUrl}app/searchResults/searchResults?searchTerm=${clientEmail}`;
  const clientName = (
    <a href={infusionsoftLink} target="_blank" rel="noopener noreferrer">
      {props.clientName}
    </a>
  );

  const sendUpdate = async (urlPath, method, data, messageType) => {
    const confMessage = `Are you sure you want to reset the assessment for booking #'${props.trackingId}'?`;
    if (!window.confirm(confMessage)) {
      return false;
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/${urlPath}`;
      const content = data ? JSON.stringify(data) : null;
      const params = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      };
      await sendRequest(url, method, content, params);
      forceUpdate(); // not working
      window.location.reload(); // need to remove when we find a better way to refresh
    } catch (err) {
      console.log('Error submitting request: ', err);
    }
  };

  const deleteAssessmentHandler = async evt =>
    sendUpdate(`assessments/${props.id}`, 'DELETE', null, 'delete');

  const DeleteButton = () => (
    <IconButton
      className={classes.deleteBtn}
      color="secondary"
      onClick={deleteAssessmentHandler}
    >
      <RotateLeftIcon />
    </IconButton>
  );

  return (
    <TableRow key={props.trackingId}>
      <TableCell component="th" scope="row">
        {props.trackingId}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        <span style={{ color: statusColors[props.assessmentStatus] }}>
          {camelCaseToTitleCase(props.assessmentStatus)}
        </span>
      </TableCell>
      <TableCell align="right">
        {moment(props.scheduled).format('M/DD/YY')}
      </TableCell>
      <TableCell align="right">{clientName}</TableCell>
      <TableCell align="right">{props.clientEmail}</TableCell>
      <TableCell align="right">{props.coachName}</TableCell>
      <TableCell align="right">{camelCaseToTitleCase(props.outcome)}</TableCell>
      <TableCell align="right">
        <Link
          target="_blank"
          className={classes.btnLink}
          to={`/assessments/edit/${props.id}`}
        >
          <Button
            className={classes.editBtn}
            size="small"
            startIcon={<EditIcon />}
            fullWidth
          >
            Edit
          </Button>
        </Link>
      </TableCell>
      <TableCell align="right">
        <Link
          target="_blank"
          className={classes.btnLink}
          to={`/assessments/view/${props.id}`}
        >
          <Button
            className={classes.viewBtn}
            color="primary"
            size="small"
            startIcon={<VisibilityIcon />}
            fullWidth
          >
            View
          </Button>
        </Link>
      </TableCell>
      <TableCell>
        <DeleteButton />
      </TableCell>
    </TableRow>
  );
};

export default EditorAssessmentsIndexItem;
