import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const AssessmentTime = (props) => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const handleChange = evt => update(question.id, evt.target.value);

  return (
    <div>
      <Typography>
        {question.text}
      </Typography>
      <TextField
        id="time"
        label="Alarm clock"
        type="time"
        value={answer}
        onChange={handleChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: 300 // 5 min
        }}
      />
    </div>
  );
};

export default AssessmentTime;
