import React from 'react';
import { Link } from 'react-router-dom';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import TimerIcon from '@material-ui/icons/Timer';
import { makeStyles } from '@material-ui/core/styles';

import { useHttpClient } from '../../shared/hooks/http-hook';

const useStyles = makeStyles(theme => ({
  authUser: {
    backgroundColor: '#eaf6ff'
  },
  editBtnLink: {
    textDecoration: 'none'
  },
  disabled: {
    color: '#888'
  }
}));

const EditorUsersIndexItem = props => {
  const { sendRequest } = useHttpClient();
  const classes = useStyles();
  const { auth, updated, setUpdated } = props;

  const sendUpdate = async (urlPath, method, data, messageType) => {
    const confMessage = `Are you sure you want to ${messageType} user '${props.email}'?`;
    if (!window.confirm(confMessage)) {
      return false;
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/${urlPath}`;
      const content = data ? JSON.stringify(data) : null;
      const params = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      };
      await sendRequest(url, method, content, params);
      setUpdated(updated + 1);
    } catch (err) {
      console.log('Error submitting request: ', err);
    }
  };

  const deleteUserHandler = async evt =>
    sendUpdate(`users/${props.id}`, 'DELETE', null, 'delete');

  const enableUserHandler = async evt =>
    sendUpdate(
      'users/update',
      'PUT',
      { id: props.id, status: 'active' },
      'enable'
    );

  const disableUserHandler = async evt =>
    sendUpdate(
      'users/update',
      'PUT',
      { id: props.id, status: 'disabled' },
      'disable'
    );

  const EditButton = () => (
    <Link className={classes.editBtnLink} to={'/editor/users/edit/' + props.id}>
      <Button variant="outlined" color="primary" startIcon={<EditIcon />}>
        Edit
      </Button>
    </Link>
  );
  const EnableButton = () => (
    <Button
      variant="outlined"
      // color="success"
      startIcon={<TimerIcon />}
      onClick={enableUserHandler}
    >
      Enable
    </Button>
  );
  const DisableButton = () => (
    <Button
      variant="outlined"
      // color="warning"
      startIcon={<TimerOffIcon />}
      onClick={disableUserHandler}
    >
      Retire
    </Button>
  );
  const DeleteButton = () => (
    <IconButton color="secondary" onClick={deleteUserHandler}>
      <DeleteIcon />
    </IconButton>
  );

  const isDisabled = props.status === 'disabled';
  const tableCellClass = isDisabled ? classes.disabled : '';

  return (
    <TableRow
      key={props.id}
      className={props.isAuthUser ? classes.authUser : ''}
    >
      <TableCell className={tableCellClass} component="th" scope="row">
        {props.name}
      </TableCell>
      <TableCell className={tableCellClass} align="center">
        {props.email}
      </TableCell>
      <TableCell className={tableCellClass} align="center">
        {props.role.charAt(0).toUpperCase() + props.role.substr(1)}
      </TableCell>
      <TableCell className={tableCellClass} align="center">
        <em>{isDisabled ? 'Disabled' : 'Active'}</em>
      </TableCell>
      <TableCell align="center">
        <EditButton />
      </TableCell>
      <TableCell align="center">
        {isDisabled ? <EnableButton /> : <DisableButton />}
      </TableCell>
      <TableCell align="center">
        <DeleteButton />
      </TableCell>
    </TableRow>
  );
};

export default EditorUsersIndexItem;
