import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { useHttpClient } from '../../../shared/hooks/http-hook';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0)
  },
  button: {
    marginTop: theme.spacing(3)
  },
  text: {
    marginTop: theme.spacing(2),
    width: '400px'
  }
}));

const logAsync = data =>
  new Promise(resolve => window.setTimeout(() => resolve(data), 300));


const GoogleDriveForm = props => {
  const { booking, setHasGoogleDriveLink } = props;
  const { clientName, clientPhone, clientEmail } = booking;
  const classes = useStyles();
  const { sendRequest } = useHttpClient();
  const [ buttonIsHidden, setButtonIsHidden ] = useState(false);

  const createGoogleDriveFolder = async () => {
    setButtonIsHidden(true);
    const url = `https://hooks.zapier.com/hooks/catch/809758/ohlhfjx/`;
    const method = 'POST';
    const data = JSON.stringify({ clientName, clientPhone, clientEmail });
    try {
      const result = await (process.env.NODE_ENV === 'production'
        ? sendRequest(url, method, data)
        : logAsync({ url, method, data }));

      window.setTimeout(() => {
        setHasGoogleDriveLink(result);
      }, 3000);
    } catch (err) {
      console.log("Error submitting Zapier / Google Drive info: ", err);
    }
  };


  return (
    <div className={classes.root}>
      <form>
        <Typography variant="h6">No Google Drive folder found</Typography>
        <Typography variant="subtitle1">
          Please click the button below ONCE to generate one (it will take a few
          seconds):
        </Typography>
        {!buttonIsHidden && (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            endIcon={<SendIcon />}
            onClick={createGoogleDriveFolder}
          >
            Create Folder
          </Button>
        )}
      </form>
    </div>
  );
};

export default GoogleDriveForm;
