import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  asterisk: {
    color: theme.palette.secondary.main
  }
}));

const RequiredAsterisk = () => {
  const classes = useStyles();
  return <span className={classes.asterisk}>*</span>;
};

export default RequiredAsterisk;