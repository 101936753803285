import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import AssessmentsIndexList from '../components/AssessmentsIndexList';
import DateRangeSelect from '../../shared/components/DateRangeSelect';

import ErrorDialog from '../../shared/components/ErrorDialog';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const AssessmentsIndex = props => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [loadedAssessments, setLoadedAssessments] = useState();

  const lastWeek = moment()
    .subtract(6, 'days')
    .format('YYYY-MM-DD');
  const today = moment().format('YYYY-MM-DD');

  const [dateRange, setDateRange] = useState({
    since: lastWeek,
    until: today
  });

  const updateDateRange = (dateParam, date) => {
    const invalidRange =
      dateParam === 'since' && moment(date) > moment(dateRange.until);
    const newDateRange = invalidRange
      ? { since: date, until: date }
      : { ...dateRange, [dateParam]: date };
    setDateRange(newDateRange);
  };

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const since = moment(dateRange.since).toISOString();
        const until = moment(dateRange.until).add(1, 'days').toISOString();
        const query = `?since=${since}&until=${until}&by=scheduled`;
        const url = `${process.env.REACT_APP_BACKEND_URL}/bookings/${query}`;
        const { bookings } = await sendRequest(url);
        let assessments = bookings
          .filter(booking => booking.assessment)
          .map(booking => {
            const { assessment } = booking;
            assessment.booking = booking;
            return assessment;
          })
          .filter(a => a.completed)
          .filter(a => a.booking.coachName === auth.name);

        setLoadedAssessments(assessments);
      } catch (err) {}
    };
    fetchAssessments();
  }, [sendRequest, dateRange, auth]);


  return (
    <section>
      <Typography variant="h4" className={classes.title}>
        My Assessments
      </Typography>
      <ErrorDialog error={error} open={!!error} onClose={clearError} />
      <DateRangeSelect dateRange={dateRange} update={updateDateRange} />
      <Typography variant="subtitle1">
        (Searches assessments by the corresponding booking's "scheduled" date)
      </Typography>
      {isLoading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!isLoading && loadedAssessments && (
        <AssessmentsIndexList assessments={loadedAssessments} />
      )}
    </section>
  );
};

export default AssessmentsIndex;
