import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import BookingsIndexList from '../components/BookingsIndexList';

import ErrorDialog from '../../shared/components/ErrorDialog';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const BookingsIndex = props => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [loadedBookings, setLoadedBookings] = useState();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/bookings/user/${auth.userId}`;
        let { bookings } = await sendRequest(url);
        setLoadedBookings(bookings);
      } catch (err) {}
    };
    fetchBookings();
  }, [sendRequest, auth]);


  return (
    <section>
      <Typography variant="h4" className={classes.title}>
        My Bookings
      </Typography>
      <ErrorDialog error={error} open={!!error} onClose={clearError} />
      {isLoading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!isLoading && loadedBookings && (
        <BookingsIndexList bookings={loadedBookings} />
      )}
    </section>
  );
};

export default BookingsIndex;
