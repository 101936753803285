// note: Zapier doesn't accept 'Content-Type': 'application/json' in headers

const logAsync = data =>
  new Promise(resolve => window.setTimeout(() => resolve(data), 300));


const sendCoachFeedbackEmail = async (assessment, answers, coachEmail, sendRequest) => {
  const { requestFeedback } = answers;
  if (requestFeedback !== 'yes') {
    return true;
  }
  console.log('Sending "request feedback" email...');

  const { requestFeedbackAnythingSpecific } = answers;
  const {
    coachName,
    trackingId,
    clientName,
    clientEmail,
    clientGoogleDriveLink
  } = assessment.booking;

  const url = 'https://hooks.zapier.com/hooks/catch/809758/ohhbxa2/';
  const method = 'POST';
  const data = JSON.stringify({
    requestFeedbackAnythingSpecific,
    coachName,
    coachEmail,
    trackingId,
    clientName,
    clientEmail,
    clientGoogleDriveLink
  });

  try {
    const result = await (process.env.NODE_ENV === 'production'
      ? sendRequest(url, method, data)
      : logAsync({ url, method, data }));
    console.log('Feedback email result: ', result);
    // console.log(url, method, data);
  } catch (err) {
    console.log('Error posting to Zapier: ', err);
  }
};

const sendOnboardingDepositEmail = async (assessment, answers, conf, sendRequest) => {
  const { scheduledCall, outcome } = answers;
  if (!(scheduledCall === 'spokeToClient' && outcome === 'deposit')) {
    return true;
  }
  console.log('Sending "client onboarding" email...');

  const { trackingId, clientName, clientEmail, coachName } = assessment.booking;
  const {
    depositAmountCollected,
    chosenEnrollmentOption,
    confirmClientFirstName,
    confirmClientLastName,
    canEmailBeSentImmediately,
    reasonForHoldingOff,
    startDate,
    remainingBalance,
    nextPayment
  } = answers;

  const amountText = conf
      .getQuestions()
      .find(question => question.id === 'depositAmountCollected')
      .options.find(option => option.id === depositAmountCollected).text;

  const enrollmentText = conf
      .getQuestions()
      .find(question => question.id === 'chosenEnrollmentOption')
      .options.find(option => option.id === chosenEnrollmentOption).text;

  const onboardingSendStatus =
      canEmailBeSentImmediately === 'goAhead'
          ? `<p><strong>CLEARED FOR IMMEDIATE DISPATCH.</strong></p>`
          : `<p><strong style="color: red;">DO NOT SEND YET</strong></p><p><strong>REASON:</strong> ${reasonForHoldingOff ||
          '(No reason given.)'} </p>`;

  const url = `https://hooks.zapier.com/hooks/catch/809758/otn60ev/`;
  const method = 'POST';
  const data = JSON.stringify({
    trackingId,
    clientName,
    clientEmail,
    coachName,
    clientFirstName: confirmClientFirstName,
    clientLastName: confirmClientLastName,
    onboardingSendStatus,
    amountText,
    enrollmentText,
    startDate,
    remainingBalance,
    nextPayment
  });

  try {
    const result = await (process.env.NODE_ENV === 'production'
      ? sendRequest(url, method, data)
      : logAsync({ url, method, data }));
    console.log('Onboarding email result: ', result);
  } catch (err) {
    console.log('Error posting to Zapier: ', err);
  }
};

const sendOnboardingEnrolledEmail = async (assessment, answers, conf, sendRequest) => {
  const { scheduledCall, outcome } = answers;
  if (!(scheduledCall === 'spokeToClient' && outcome === 'enrolled')) {
    return true;
  }
  console.log('Sending "client onboarding" email...');

  const { trackingId, clientName, clientEmail, coachName } = assessment.booking;
  const {
    confirmClientFirstName,
    confirmClientLastName,
    canEmailBeSentImmediately,
    reasonForHoldingOff
  } = answers;

  const onboardingSendStatus =
      canEmailBeSentImmediately === 'goAhead'
          ? `<p><strong>CLEARED FOR IMMEDIATE DISPATCH.</strong></p>`
          : `<p><strong style="color: red;">DO NOT SEND YET</strong></p><p><strong>REASON:</strong> ${reasonForHoldingOff ||
          '(No reason given.)'} </p>`;

  const url = `https://hooks.zapier.com/hooks/catch/809758/ohfqzze/`;
  const method = 'POST';
  const data = JSON.stringify({
    trackingId,
    clientName,
    clientEmail,
    coachName,
    clientFirstName: confirmClientFirstName,
    clientLastName: confirmClientLastName,
    onboardingSendStatus
  });

  try {
    const result = await (process.env.NODE_ENV === 'production'
        ? sendRequest(url, method, data)
        : logAsync({ url, method, data }));
    console.log('Onboarding email result: ', result);
  } catch (err) {
    console.log('Error posting to Zapier: ', err);
  }
};

const sendOnboardingFinancedEmail = async (assessment, answers, conf, sendRequest) => {
  const { scheduledCall, outcome } = answers;
  if (!(scheduledCall === 'spokeToClient' && outcome === 'financed')) {
    return true;
  }
  console.log('Sending "client onboarding" email...');

  const { trackingId, clientName, clientEmail, coachName } = assessment.booking;
  const {
    confirmClientFirstName,
    confirmClientLastName,
    canEmailBeSentImmediately,
    reasonForHoldingOff
  } = answers;

  const onboardingSendStatus =
      canEmailBeSentImmediately === 'goAhead'
          ? `<p><strong>CLEARED FOR IMMEDIATE DISPATCH.</strong></p>`
          : `<p><strong style="color: red;">DO NOT SEND YET</strong></p><p><strong>REASON:</strong> ${reasonForHoldingOff ||
          '(No reason given.)'} </p>`;

  const url = `https://hooks.zapier.com/hooks/catch/809758/orghdtd/`;
  const method = 'POST';
  const data = JSON.stringify({
    trackingId,
    clientName,
    clientEmail,
    coachName,
    clientFirstName: confirmClientFirstName,
    clientLastName: confirmClientLastName,
    onboardingSendStatus
  });

  try {
    const result = await (process.env.NODE_ENV === 'production'
        ? sendRequest(url, method, data)
        : logAsync({ url, method, data }));
    console.log('Onboarding email result: ', result);
  } catch (err) {
    console.log('Error posting to Zapier: ', err);
  }
};

const sendOnboardingBadFitEmail = async (assessment, answers, conf, sendRequest) => {
  const { scheduledCall, outcome } = answers;
  if (!(scheduledCall === 'spokeToClient' && outcome === 'badFit')) {
    return true;
  }
  console.log('Sending "client onboarding" email...');

  const { trackingId, clientName, clientEmail, coachName } = assessment.booking;

  const clientNameParts = clientName.split(' ');

  const url = `https://hooks.zapier.com/hooks/catch/809758/orgrslu/`;
  const method = 'POST';
  const data = JSON.stringify({
    trackingId,
    clientName,
    clientEmail,
    coachName,
    clientFirstName: clientNameParts[0],
    clientLastName: clientNameParts[1]
  });

  try {
    const result = await (process.env.NODE_ENV === 'production'
        ? sendRequest(url, method, data)
        : logAsync({ url, method, data }));
    console.log('Onboarding email result: ', result);
  } catch (err) {
    console.log('Error posting to Zapier: ', err);
  }
};

const applyInfusionsoftTags = async (assessment, answers, sendRequest) => {
  const prefix = '10. Marketing - Webinar to Consult - Button:';
  const tags = {
    [`${prefix} 06. Showed Up - Needs to Reschedule Button`]: {
      scheduledCall: 'needsReschedule'
    },
    [`${prefix} 06. Rescheduled`]: [
      {
        scheduledCall: 'rescheduled'
      },
      {
        scheduledCall: 'clientRescheduled'
      }
    ],
    [`${prefix} 07. Didn't Show Up Button`]: {
      scheduledCall: 'noShow'
    },
    [`${prefix} 07b. 2nd No-Show`]: {
      scheduledCall: 'secondNoShow'
    },
    [`${prefix} 09 Canceled Appointment Button`]: {
      scheduledCall: 'cancellation'
    },
    [`${prefix} 08. Not Ready - No Offer Made Button`]: {
      scheduledCall: 'spokeToClient',
      outcome: 'notReady'
    },
    [`${prefix} 08. Bought`]: {
      scheduledCall: 'spokeToClient',
      outcome: 'enrolled'
    },
    [`${prefix} 08. Deposit`]: {
      scheduledCall: 'spokeToClient',
      outcome: 'deposit'
    },
    [`${prefix} 08. Financed 12k`]: {
      scheduledCall: 'spokeToClient',
      outcome: 'financed'
    },
    [`${prefix} 08. Didn't Buy - Bad Fit Button`]: [
      {
        scheduledCall: 'spokeToClient',
        outcome: 'badFit'
      },
      {
        scheduledCall: 'cancellationBadFit'
      }
    ],
    [`${prefix} 08. Didn't Buy Good Fit Button`]: {
      scheduledCall: 'spokeToClient',
      outcome: 'goodFit'
    },
    [`${prefix} 06. Showed Up Button`]: {
      scheduledCall: 'spokeToClient',
      outcome: 'other'
    }
  };

  const meetsConditions = conds => {
    return Object.keys(conds).every(key => {
      const allowed = conds[key];
      return allowed === answers[key];
    });
  }

  const tagToApply = Object.keys(tags).find(tag => {
    const conds = tags[tag];
    return Array.isArray(conds)
      ? conds.some(cond => meetsConditions(cond))
      : meetsConditions(conds);
  });

  if (!tagToApply) {
    return true;
  }
  console.log('Applying Infusionsoft tag...', tagToApply);


  const url = 'https://hooks.zapier.com/hooks/catch/809758/otntoei/';
  const method = 'POST';
  const data = JSON.stringify({
    tag: tagToApply,
    email: assessment.booking.clientEmail
  });

  try {
    const result = await (process.env.NODE_ENV === 'production'
      ? sendRequest(url, method, data)
      : logAsync({ url, method, data }));
    console.log('Infusionsoft tag result: ', result);
    // console.log(url, method, data);
  } catch (err) {
    console.log('Error posting to Zapier/Infusionsoft: ', err);
  }
};


const sendOtherOutcomeEmail = async (assessment, answers, sendRequest) => {
  const { scheduledCall, outcome } = answers;
  if (!(scheduledCall === 'spokeToClient' && outcome === 'other')) {
    return true;
  }
  console.log('Sending "other outcome" email...');


  const { trackingId, clientName, coachName } = assessment.booking;
  const {
    enterOtherOutcome
  } = answers;

  const url = 'https://hooks.zapier.com/hooks/catch/809758/or8k888/';
  const method = 'POST';
  const data = JSON.stringify({
    trackingId,
    clientName,
    coachName,
    enterOtherOutcome
  });

  try {
    const result = await (process.env.NODE_ENV === 'production'
        ? sendRequest(url, method, data)
        : logAsync({ url, method, data }));
    console.log('Other outcome email result: ', result);
  } catch (err) {
    console.log('Error posting to Zapier: ', err);
  }
};

export {
  sendCoachFeedbackEmail,
  sendOnboardingEnrolledEmail,
  sendOnboardingDepositEmail,
  sendOnboardingFinancedEmail,
  sendOnboardingBadFitEmail,
  applyInfusionsoftTags,
  sendOtherOutcomeEmail
};
