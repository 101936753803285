import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

// import EmailIcon from '@material-ui/icons/Email';
// import LockIcon from '@material-ui/icons/Lock';

// import TextField from '@material-ui/core/TextField';


import Input from '../../shared/components/FormElements/Input';
import ErrorDialog from '../../shared/components/ErrorDialog';
// import ImageUpload from '../../shared/components/FormElements/ImageUpload';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  figure: {
    padding: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const UsersLogin = () => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const switchModeHandler = () => {
    if (!isLoginMode) {
      const data = {
        ...formState.inputs,
        name: undefined,
        // image: undefined
      };
      const validStatus = formState.inputs.email.isValid && formState.inputs.password.isValid;
      setFormData(data, validStatus);
    } else {
      const data = {
        ...formState.inputs,
        name: { value: '', isValid: false },
        // image: { value: null, isValid: false }
      };
      setFormData(data, false);
    }
    setIsLoginMode(prevMode => !prevMode);
  };

  const authSubmitHandler = async event => {
    event.preventDefault();

    if (isLoginMode) {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/users/login`;
        const method = 'POST';
        const data = JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value
        });
        const headers = { "Content-Type": "application/json" };
        const responseData = await sendRequest(url, method, data, headers);
        auth.login(responseData);
        history.push('/bookings');
      } catch (err) {}
    } else {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/users/signup`;
        // const formData = new FormData();
        // formData.append('email', formState.inputs.email.value);
        // formData.append('name', formState.inputs.name.value);
        // formData.append('password', formState.inputs.password.value);
        // formData.append('image', formState.inputs.image.value);
        // const responseData = await sendRequest(url, 'POST', formData);
        const data = JSON.stringify({
          email: formState.inputs.email.value,
          name: formState.inputs.name.value,
          password: formState.inputs.password.value
        });
        const headers = { 'Content-Type': 'application/json' };
        const responseData = await sendRequest(url, 'POST', data, headers);
        auth.login(responseData);
        history.push('/bookings');
      } catch (err) {}
    }
  };

  return (
    <Container maxWidth="sm">
      <ErrorDialog error={error} open={!!error} onClose={clearError} />
      <Card className={classes.figure}>
        {isLoading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress />
          </Backdrop>
        )}
        <Typography variant="h4">Log in</Typography>
        <hr />
        <form onSubmit={authSubmitHandler}>
          {!isLoginMode && (
            <React.Fragment>
              <Input
                element="input"
                id="name"
                type="text"
                label="Your Name"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a name."
                onInput={inputHandler}
              />
            </React.Fragment>
          )}
          {/* {!isLoginMode && (
            <ImageUpload
              center
              id="image"
              onInput={inputHandler}
              errorText="Please provide an image."
            />
          )} */}
          <div style={{ marginTop: '10px' }}></div>
          <Input
            element="input"
            id="email"
            type="email"
            label="Email"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
            onInput={inputHandler}
            // icon={<EmailIcon}
          />
          <div style={{ marginTop: '10px' }}></div>
          <Input
            element="input"
            id="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
            onInput={inputHandler}
            // icon={LockIcon}
          />
          <Button
            style={{ marginTop: '24px' }}
            color="secondary"
            variant="contained"
            type="submit"
            disabled={!formState.isValid}
          >
            {isLoginMode ? 'Log in' : 'Sign up'}
          </Button>
        </form>
        <div style={{ float: 'right', textAlign: 'right' }} hidden>
          <Typography variant="body2" style={{ display: 'inline' }}>
            {isLoginMode
              ? "Don't have an account?"
              : 'Already have an account?'}
          </Typography>
          <Button size="small" color="primary" onClick={switchModeHandler}>
            {isLoginMode ? 'Sign up' : 'Log in'}
          </Button>
        </div>
      </Card>
    </Container>
  );
};

export default UsersLogin;
