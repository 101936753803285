
import { LOGIN, LOGOUT } from '../action-types';


const initialState = {
  token: false,
  userId: false,
  name: false,
  email: false,
  role: false,
  bookingPageUrl: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state };
    case LOGOUT:
      return { ...state };
    default:
      return state;
  }
};

export default authReducer;
