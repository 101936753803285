import React from 'react';
import { Link } from 'react-router-dom';

// import Icon from "@material-ui/core/Icon";
// import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Nav from './Nav';
import NavDesktop from './NavDesktop';
// import NavMobile from './NavMobile';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  titleLink: {
    color: 'white',
    textDecoration: 'none'
  }
}));


const Header = (props) => {

  const classes = useStyles();

  // const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  // const openMobileNav = () => {
  //   setDrawerIsOpen(true);
  // };

  // const closeMobileNav = () => {
  //   setDrawerIsOpen(false);
  // };

  return (
    <header className={classes.root}>
      {/* {drawerIsOpen && <Backdrop onClick={closeMobileNav} />} */}

      {/* <NavMobile show={drawerIsOpen} onClick={closeMobileNav}>
        <nav className="main-navigation__drawer-nav">
          <Nav />
        </nav>
      </NavMobile> */}

      <NavDesktop>
        {/* <IconButton
          edge="start"
          // className="main-navigation__menu-btn"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={openMobileNav}
        >
          <Icon>menu</Icon>
        </IconButton> */}
        <Typography variant="h6" className={classes.title}>
          <Link to="/" className={classes.titleLink}>
            VestRight
          </Link>
        </Typography>
        <nav className="main-navigation__header-nav">
          <Nav />
        </nav>
      </NavDesktop>
    </header>
  );
};

export default Header;
