import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

const DateRangeSelect = props => {
  const { dateRange, update } = props;
  const since = moment(dateRange.since);
  const until = moment(dateRange.until);

  const sinceChangeHandler = date => {
    update('since', moment(date).format('YYYY-MM-DD'));
  };
  const untilChangeHandler = date => {
    update('until', moment(date).format('YYYY-MM-DD'));
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container justify="flex-start">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-range-since"
          label="Since"
          value={since}
          onChange={sinceChangeHandler}
          KeyboardButtonProps={{
            'aria-label': 'change date: since'
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-range-until"
          label="Until"
          value={until}
          onChange={untilChangeHandler}
          KeyboardButtonProps={{
            'aria-label': 'change date: until'
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangeSelect;
