import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorDialog from '../../shared/components/ErrorDialog';
import EditorBookingsEditForm from '../components/EditorBookingsEditForm';
import { AuthContext } from '../../shared/context/auth-context';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

const EditorBookingsEdit = () => {
  const classes = useStyles();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { bookingId } = useParams();
  const auth = useContext(AuthContext);

  const [loadedBooking, setLoadedBooking] = useState();
  const [loadedUsers, setLoadedUsers] = useState();


  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/bookings/${bookingId}`;
        const { booking } = await sendRequest(url);
        setLoadedBooking(booking);
      } catch (err) {}
    };
    fetchBooking();
  }, [sendRequest, bookingId]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/users`;
        let { users } = await sendRequest(url);
        users =
          auth.role === 'superadmin'
            ? users
            : users.filter(user => user.role !== 'superadmin');
        setLoadedUsers(users);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth]);


  return (
    <section>
      <Container maxWidth="md">
        <Link to="/editor/bookings">Back to Bookings index</Link>
        <div className={classes.paper}>
          <ErrorDialog error={error} open={!!error} onClose={clearError} />
          <Avatar className={classes.avatar}>
            <EditIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update Booking
            {loadedBooking && ` "${loadedBooking.trackingId}"`}
          </Typography>
          {isLoading && (
            <div className="center">
              <CircularProgress />
            </div>
          )}
        </div>
        <Grid container>
          <Grid item sm={6}>
            <Typography variant="h6" style={{ marginTop: '24px' }}>
              Read-only data:
            </Typography>
            {loadedBooking && (
              <div style={{ marginRight: '20px', wordWrap: 'break-word' }}>
                <p>
                  ScheduleOnce tracking ID:{' '}
                  <strong>{loadedBooking.trackingId}</strong>
                </p>
                <p>
                  Created:{' '}
                  <strong>
                    {moment(loadedBooking.created).format('M/DD/YY @ LT')}
                  </strong>
                </p>
                <p>
                  Updated:{' '}
                  <strong>
                    {moment(loadedBooking.updated).format('M/DD/YY @ LT')}
                  </strong>
                </p>
                <p>
                  Scheduled:{' '}
                  <strong>
                    {moment(loadedBooking.scheduled).format('M/DD/YY @ LT')}
                  </strong>
                </p>
                <p>
                  Google Drive Link:{' '}
                  <strong>{loadedBooking.clientGoogleDriveLink}</strong>
                </p>
                <p>
                  Gross Annual Income:{' '}
                  <strong>{loadedBooking.clientGrossAnnualIncome}</strong>
                </p>
                <p>
                  UTM Campaign: <strong>{loadedBooking.utmCampaign}</strong>
                  <br />
                  UTM Source: <strong>{loadedBooking.utmSource}</strong>
                  <br />
                  UTM Medium: <strong>{loadedBooking.utmMedium}</strong>
                  <br />
                  UTM Content: <strong>{loadedBooking.utmContent}</strong>
                  <br />
                  UTM Term: <strong>{loadedBooking.utmTerm}</strong>
                </p>
              </div>
            )}
          </Grid>
          <Grid item sm={6}>
            <div className={classes.paper}>
              {!isLoading && loadedBooking && loadedUsers && (
                <EditorBookingsEditForm
                  booking={loadedBooking}
                  users={loadedUsers}
                  sendRequest={sendRequest}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default EditorBookingsEdit;
