import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  commentContainer: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  commentContainerCompressed: {
    margin: theme.spacing(0, 0, 1, 0)
  }
}));

const AssessmentComment = props => {
  const { question } = props;
  const classes = useStyles();

  return (
    <div
      className={
        question.compressTop
          ? classes.commentContainerCompressed
          : classes.commentContainer
      }
    >
      <Typography variant="h6">{question.text}</Typography>
      {question.subtext &&
        (question.subtext.includes('<') ? (
          ReactHtmlParser(question.subtext)
        ) : (
          <Typography variant="subtitle1">{question.subtext}</Typography>
        ))}
      {question.body && ReactHtmlParser(question.body)}
    </div>
  );
};

export default AssessmentComment;
