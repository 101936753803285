import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import BookingStatsList from './BookingStatsList';


const BookingStatsTab = props => {
  const { bookings, dateRange} = props;

  if (bookings.length === 0) {
    return (
      <Typography style={{ margin: '26px 0' }} variant="h6">
        No bookings found.
      </Typography>
    );
  }

  const since = moment(dateRange.since);
  const until = moment(dateRange.until).endOf('day');;

  const bookingsWithinRange = bookings.filter(booking => {
    const created = moment(booking.created);
    return created >= since && created <= until;
  });

  const data = bookingsWithinRange.map(booking => {
    const assessmentStatus = (function() {
      const pastScheduledDay = moment(booking.scheduled) < moment().endOf('day');
      const isPending = booking.assessment && booking.assessment.pending;
      const isCompleted = booking.assessment && booking.assessment.completed;
      const statuses = {
        waiting: !isCompleted && !isPending && !pastScheduledDay,
        pastDue: !isCompleted && !isPending && pastScheduledDay,
        pending: !isCompleted && isPending,
        complete: isCompleted
      };

      return Object.keys(statuses).find(status => statuses[status]);
    })();

    const callOutcome = (function() {
      const answers = booking.assessment && booking.assessment.answers;
      const outcome = answers && answers.outcome;
      const scheduledCall = answers && answers.scheduledCall;
      return outcome || scheduledCall || '';
    })();

    return {
      key: booking.id,
      trackingId: booking.trackingId,
      created: booking.created,
      name: booking.clientName,
      scheduled: booking.scheduled,
      assessmentStatus,
      campaignName: booking.utmCampaign,
      grossAnnualIncome: booking.clientGrossAnnualIncome,
      callOutcome,
      coachName: booking.coachName
    };
  });

  return <BookingStatsList data={data} />;
};

export default BookingStatsTab;
