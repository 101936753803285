import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  totalsRow: {
    background: theme.palette.primary.main
  },
  totalsCell: {
    color: 'white'
  }
}));


const addCommas = str => String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const toUSD = n => '$' + addCommas(n.toFixed(2));


const CampaignStatsItem = props => {
  const { totals } = props;

  const classes = useStyles();

  return (
    <TableRow className={totals ? classes.totalsRow : ''}>
      <TableCell
        className={totals ? classes.totalsCell : ''}
        component="th"
        scope="row"
      >
        {props.name}
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {toUSD(props.spend)}
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {Number(props.clicksPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.clicksCount)})</em>
        </small>
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {toUSD(props.cpc)}
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {props.registrationsPercent.toFixed(2)}%
        <small>
          <em>({addCommas(props.registrationsCount)})</em>
        </small>
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {props.attendedPercent.toFixed(2)}%
        <small>
          <em>({addCommas(props.attendedCount)})</em>
        </small>
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {props.bookingsPercent.toFixed(2)}%
        <small>
          <em>({addCommas(props.bookingsCount)})</em>
        </small>
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {toUSD(props.costPerBooking)}
      </TableCell>
      <TableCell className={totals ? classes.totalsCell : ''} align="right">
        {addCommas(props.enrollments)}
      </TableCell>
    </TableRow>
  );
};

export default CampaignStatsItem;
