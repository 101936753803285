import React, { useState, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { AuthContext } from '../../context/auth-context';

const useStyles = makeStyles(theme => ({
  menuLink: {
    color: 'black',
    textDecoration: 'none'
  },
  navButton: {
    color: 'white',
    textDecoration: 'none'
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    marginRight: '20px'
  },
  navLinkActive: {
    '& button': {
      textShadow: '0 0 3px white',
      borderBottom: '1px solid white'
    }
  },
  authUser: {
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: theme.spacing(4)
  }
}));

const Nav = props => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    history.push('/users/login');
  };

  const NavLinkDefault = props => {
    const { to, children } = props;
    return (
      <NavLink
        to={to}
        exact
        activeClassName={classes.navLinkActive}
        className={classes.navLink}
      >
        <Button color="inherit">{children}</Button>
      </NavLink>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="body2" className={classes.authUser}>
        {auth.name && auth.name.split(' ')[0]}
      </Typography>
      {auth.isLoggedIn && (
        <React.Fragment>
          <NavLinkDefault to="/bookings">
            My Bookings
          </NavLinkDefault>
          <NavLinkDefault to="/assessments">
            My Assessments
          </NavLinkDefault>
          {auth.role.includes('admin') && (
            <React.Fragment>
              <NavLinkDefault to="/statistics">Statistics</NavLinkDefault>
              <Button
                className={classes.navLink}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                Editor
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <NavLink to="/editor/users" exact className={classes.menuLink}>
                    Users
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to="/editor/bookings" exact className={classes.menuLink}>
                    Bookings
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to="/editor/assessments" exact className={classes.menuLink}>
                    Assessments
                  </NavLink>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
          <Button variant="contained" onClick={handleLogout} color="secondary">
            Log out
          </Button>
        </React.Fragment>
      )}
      {!auth.isLoggedIn && (
        <NavLink to="/users/login" className={classes.navButton}>
          <Button color="inherit">Log in</Button>
        </NavLink>
      )}
    </React.Fragment>
  );
};

export default Nav;
