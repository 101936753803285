import React from 'react';
import moment from 'moment';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const BookingStatsItem = props => {

  const camelCaseToTitleCase = str =>
    str.charAt(0).toUpperCase() +
    str
      .substr(1)
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .trim();
  const statusColors = {
    pastDue: 'red',
    waiting: 'grey',
    pending: 'blue',
    complete: '#15af15'
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {props.trackingId}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {moment(props.created).format('M/DD/YY')}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {props.name}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {moment(props.scheduled).format('M/DD/YY')}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        <span style={{ color: statusColors[props.assessmentStatus] }}>
          {camelCaseToTitleCase(props.assessmentStatus)}
        </span>
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {props.campaignName || '<unknown>'}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {props.grossAnnualIncome}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {camelCaseToTitleCase(props.callOutcome)}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        {props.coachName}
      </TableCell>
    </TableRow>
  );
};

export default BookingStatsItem;
