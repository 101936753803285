import React from 'react';
import moment from 'moment';

// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import Favorite from '@material-ui/icons/Favorite';
// import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

const AssessmentCustom = props => {
  const { question, answers, booking } = props;

  // const handleChange = evt => update(question.id, evt.target.checked);

  if (question.id === 'rescheduleGoogleCalendarLink') {

    const eventTitle = `Booked call: ${booking.clientName}`;

    const toGCalFormat = date => date.toISOString().replace(/-|:|\.\d\d\d/g, '');
    const date = answers['rescheduleDateTime'];

    const baseUrl = 'http://www.google.com/calendar/event';
    const fromDate = toGCalFormat(moment(date));
    const toDate = toGCalFormat(moment(date).add('30', 'min'));
    const encodedTitle = window.encodeURIComponent(eventTitle);
    const link = `${baseUrl}?action=TEMPLATE&dates=${fromDate}%2F${toDate}&text=${encodedTitle}&location=&details=`;

    return (
      <div>
        <a href={link} target="_blank" rel="noopener noreferrer">
          Google Calendar event
        </a>
      </div>
    );
  } else return <div></div>
};

export default AssessmentCustom;
