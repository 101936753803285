import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import CampaignStatsList from './CampaignStatsList';


const getPercent = (a, b) => (a / (b > 0 ? b : 1)) * 100;
const toRationalNumber = str => (!isFinite(Number(str)) ? 0 : Number(str));
const toPositiveNumber = n => (n > 0 ? n : 1);
const getRegistrations = campaign => {
  const reg =
    campaign.unique_actions &&
    campaign.unique_actions.find(
      action => action.action_type === 'omni_complete_registration'
    );
  return reg ? Number(reg.value) : 0;
};
const getAttended = campaign => {
  const watched =
    campaign.unique_actions &&
    campaign.unique_actions.find(
      action => action.action_type === 'add_to_wishlist'
    );
  return watched ? Number(watched.value) : 0;
};


const getCampaignStatsTotals = (campaignRowData, bookings) => {
  const totalRows = campaignRowData.length;
  const totals = campaignRowData.reduce(
    (acc, campaign) => {
      Object.keys(acc)
        // .filter(fieldName => typeof campaign[fieldName] === 'number')
        .forEach(fieldName => {
          acc[fieldName] += campaign[fieldName];
        });
      return acc;
    },
    {
      spend: 0,
      clicksCount: 0,
      clicksPercent: 0,
      // cpc: 0,
      registrationsCount: 0,
      // registrationsPercent: 0,
      attendedCount: 0,
      // attendedPercent: 0
    }
  );

  totals.clicksPercent = totals.clicksPercent / totalRows;
  totals.cpc =
    toRationalNumber(totals.spend) / toPositiveNumber(totals.clicksCount);
  totals.registrationsPercent = getPercent(
    totals.registrationsCount,
    totals.clicksCount
  );
  totals.attendedPercent = getPercent(
    totals.attendedCount,
    totals.registrationsCount
  );
  totals.bookingsCount = bookings.length;
  totals.bookingsPercent = getPercent(bookings.length, totals.attendedCount);
  totals.costPerBooking = (function() {
    const cpb = Number(totals.spend) / bookings.length;
    return cpb === Infinity ? 0 : cpb;
  })();
  totals.enrollments = bookings.filter(booking => {
    try {
      return ['enrolled', 'collectedDeposit'].includes(
        booking.assessment.answers.outcome
      );
    } catch (err) {
      return false;
    }
  }).length;

  return {
    ...totals,
    key: 'totals',
    name: 'TOTALS'
  };
};


const CampaignStatsTab = props => {
  const { campaigns, bookings, dateRange } = props;

  if (campaigns.length === 0) {
    return (
      <Typography style={{ margin: '26px 0' }} variant="h6">
        No campaigns found.
      </Typography>
    );
  }

  const since = moment(dateRange.since);
  const until = moment(dateRange.until).endOf('day');;

  const bookingsWithinRange = bookings.filter(booking => {
    const created = moment(booking.created);
    return created >= since && created <= until;
  });

  const data = campaigns.map(campaign => {

    const campaignBookings = bookingsWithinRange.filter(
      booking => booking.utmCampaign === campaign.campaign_name
    );

    const key = campaign.campaign_id;
    const name = campaign.campaign_name;
    const spend = toRationalNumber(campaign.spend);

    const clicksCount = toRationalNumber(
      campaign.unique_outbound_clicks &&
        campaign.unique_outbound_clicks[0].value
    );
    const clicksPercent = toRationalNumber(
      campaign.unique_outbound_clicks_ctr &&
        campaign.unique_outbound_clicks_ctr[0].value
    );

    const cpc =
      toRationalNumber(campaign.spend) / toPositiveNumber(clicksCount);

    const registrationsCount = getRegistrations(campaign);
    const registrationsPercent = getPercent(registrationsCount, clicksCount);

    const attendedCount = getAttended(campaign);
    const attendedPercent = getPercent(attendedCount, registrationsCount);

    const bookingsCount = campaignBookings.length;
    const bookingsPercent = getPercent(bookingsCount, attendedCount);

    // const costPerBooking = Number(campaign.spend) / campaignBookings.length;
    const costPerBooking = (function() {
      const cpb = Number(campaign.spend) / campaignBookings.length;
      return cpb === Infinity ? 0 : cpb;
    })();

    const enrollments = campaignBookings.filter(booking => {
      try {
        return ['enrolled', 'collectedDeposit'].includes(
          booking.assessment.answers.outcome
        );
      } catch (err) {
        return false;
      }
    }).length;

    return {
      key,
      name,
      spend,
      clicksCount,
      clicksPercent,
      cpc,
      registrationsCount,
      registrationsPercent,
      attendedCount,
      attendedPercent,
      bookingsCount,
      bookingsPercent,
      costPerBooking,
      enrollments
    };
  });
  const totals = getCampaignStatsTotals(data, bookingsWithinRange);
  // need to copy UTM/questionnaire/client data from oldest booking

  return (
    <CampaignStatsList data={data} totals={totals} />
  );
};

export default CampaignStatsTab;
