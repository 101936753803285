import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import CampaignStatsItem from './CampaignStatsItem';
import { Search, applySearch } from '../../shared/components/Search';


const stableSort = (array, sorter) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = sorter(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, prop) => {
  const normalizeValue = v => {
    switch (typeof v) {
      case 'number':
        return v;
      case 'undefined':
        return '';
      case 'object':
        return ''; // for 'null'
      default:
        return String(v);
    }
  };
  const desc = (a, b, prop) => {
    const aProp = normalizeValue(a[prop]);
    const bProp = normalizeValue(b[prop]);
    return aProp > bProp ? 1 : aProp < bProp ? -1 : 0;
  };

  return order === 'desc'
    ? (a, b) => desc(a, b, prop)
    : (a, b) => -desc(a, b, prop);
};

const headCells = [
  { id: 'name', alignRight: false, heading: 'Name' },
  { id: 'spend', alignRight: true, heading: 'Spend' },
  { id: 'clicksPercent', alignRight: true, heading: 'CTR' },
  { id: 'cpc', alignRight: true, heading: 'CPC' },
  { id: 'registrationsPercent', alignRight: true, heading: 'Registrations' },
  { id: 'attendedPercent', alignRight: true, heading: 'Attended' },
  { id: 'bookingsPercent', alignRight: true, heading: 'Bookings', tooltip: '(# / attended)' },
  { id: 'cpb', alignRight: true, heading: 'CPB', tooltip: '($ per booking)' },
  { id: 'enrollments', alignRight: true, heading: 'Enrollments' }
];

const CampaignStatsListHead = props => {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.tooltip ? (
                <Tooltip title={headCell.tooltip}>
                  <span>{headCell.heading}</span>
                </Tooltip>
              ) : (
                <span>{headCell.heading}</span>
              )}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CampaignStatsListHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const CampaignStatsList = props => {
  const { data, totals } = props;

  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('spend');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [paginate, setPaginate] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const handleChangePaginate = event => {
    setPaginate(event.target.checked);
  };

  const searchable = [
    'name',
    'spend',
    'clicksNumber',
    'clicksPercent',
    'cpc',
    'registrationsNumber',
    'registrationsPercent',
    'attendedNumber',
    'attendedPercent',
    'bookingsNumber',
    'bookingsPercent',
    'costPerBooking',
    'enrollments'
  ]

  const searchedRowData = applySearch(data, searchable, search);

  const totalRows = data.length;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalRows - page * rowsPerPage);

  const sortedRowData = stableSort(searchedRowData, getSorting(order, orderBy));
  const pagedRowData = paginate
    ? sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedRowData;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Search search={search} setSearch={setSearch} />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="CampaignStats"
            size={dense ? 'small' : 'medium'}
            aria-label="CampaignStats"
          >
            <CampaignStatsListHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <CampaignStatsItem totals {...totals} />
              {pagedRowData.map(campaign => (
                <CampaignStatsItem {...campaign} />
              ))}
              {paginate && emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={totalRows} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paginate && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Compact"
      />
      <FormControlLabel
        control={<Switch checked={paginate} onChange={handleChangePaginate} />}
        label="Paginate"
      />
    </div>
  );
}

export default CampaignStatsList;
