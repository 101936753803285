import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import CoachStatsList from './CoachStatsList';


const getPercent = (a, b) => (a / (b > 0 ? b : 1)) * 100;

const hasAnswer = (questionId, answerId) => {
  return function(booking) {
    const { assessment } = booking;
    try {
      return assessment.answers[questionId] === answerId;
    } catch (err) {
      return false;
    }
  };
}
const isCallType = answer => hasAnswer('scheduledCall', answer);
const hasOutcome = answer => hasAnswer('outcome', answer);


const getCoachStatsTotals = data => {

  const totals = data.reduce(
    (acc, coach) => {
      Object.keys(acc)
        // .filter(fieldName => typeof coach[fieldName] === 'number')
        .forEach(fieldName => {
          acc[fieldName] += coach[fieldName];
        });
      return acc;
    },
    {
      totalBookingsCount: 0,
      uniqueBookingsCount: 0,
      noShowCount: 0,
      // noShowPercent: 0,
      cancellationCount: 0,
      // cancellationPercent: 0,
      pendingCount: 0,
      // pendingPercent: 0,
      goodFitCount: 0,
      // goodFitPercent: 0,
      badFitCount: 0,
      // badFitPercent: 0,
      notReadyCount: 0,
      // notReadyPercent: 0,
      enrolledCount: 0,
      // enrolledPercent: 0,
      depositCount: 0,
      // depositPercent: 0,
      pastDue: 0,
      callsCount: 0,
      // closingRate: 0
    }
  );

  totals.noShowPercent = getPercent(totals.noShowCount, totals.uniqueBookingsCount);
  totals.cancellationPercent = getPercent(totals.cancellationCount, totals.uniqueBookingsCount);
  totals.pendingPercent = getPercent(totals.pendingCount, totals.uniqueBookingsCount);
  totals.goodFitPercent = getPercent(totals.goodFitCount, totals.uniqueBookingsCount);
  totals.badFitPercent = getPercent(totals.badFitCount, totals.uniqueBookingsCount);
  totals.notReadyPercent = getPercent(totals.notReadyCount, totals.uniqueBookingsCount);
  totals.enrolledPercent = getPercent(totals.enrolledCount, totals.uniqueBookingsCount);
  totals.depositPercent = getPercent(totals.depositCount, totals.uniqueBookingsCount);
  totals.closingRate = getPercent(totals.enrolledCount, totals.callsCount);

  return {
    ...totals,
    key: 'totals',
    name: 'TOTALS'
  };
};


const CoachStatsTab = props => {
  const { users, bookings, dateRange } = props;

  if (users.length === 0) {
    return (
      <Typography style={{ margin: '26px 0' }} variant="h6">
        No Reps found.
      </Typography>
    );
  }

  const since = moment(dateRange.since);
  const until = moment(dateRange.until).endOf('day');
  const withinDateRange = d => moment(d) >= since && moment(d) <= until;

  const data = users.map(coach => {

    // bookings owned by 'this' coach
    const coachBookings = bookings.filter(
      booking => booking.coachName === coach.name
    );

    const totalBookingsCount = coachBookings.filter(booking =>
      withinDateRange(booking.scheduled)
    ).length;
    // clientsBookings = [ [booking1, booking2], [booking1], [booking1], ... ]
    // client bookings are sorted by scheduled (oldest first)
    const clientsBookings = (function() {
      const bookingsByEmail = coachBookings.reduce((acc, booking) => {
        if (!acc[booking.clientEmail]) {
          acc[booking.clientEmail] = [];
        }
        acc[booking.clientEmail].push(booking);
        return acc;
      }, {});
      const sortByScheduled = (a, b) =>
        a.scheduled > b.scheduled ? 1 : a.scheduled < b.scheduled ? -1 : 0;
      return Object.values(bookingsByEmail).map(bookings =>
        bookings.sort(sortByScheduled)
      );
    })();

    // essentially, the last booking in a client's history
    const uniqueBookings = [];
    clientsBookings.forEach(clientBookings => {
      const finalBooking = clientBookings[clientBookings.length - 1];

      if (withinDateRange(finalBooking.scheduled)) {
        uniqueBookings.push(finalBooking);
      }
    });
    // the divisor of most of the Coach stats
    const uniqueBookingsCount = uniqueBookings.length;

    // "valid calls" (grouped by client) = bookings with a scheduledCall answer of 'spokeToClient', 'outcomePending', or 'followedUp'
    const clientsValidCalls = clientsBookings
      .map(clientBookings => {
        return clientBookings.filter(booking => {
          const answers = booking.assessment && booking.assessment.answers;
          const validCalls = ['spokeToClient', 'outcomePending', 'followedUp'];
          return validCalls.includes(answers && answers.scheduledCall);
        });
      })
      .filter(clientBookings => clientBookings.length);
    // the first "valid call" in a client's history with a coach - which is counted for the coach's 'Calls' stat
    const countedCalls = [];
    // the last "valid call" in a client's history with a coach - used for enrollments audit below
    const outcomeCalls = [];

    clientsValidCalls.forEach(clientValidCalls => {

      const countedCall = clientValidCalls[0];
      const outcomeCall = clientValidCalls[clientValidCalls.length - 1];

      if (withinDateRange(countedCall.scheduled)) {
        countedCalls.push(countedCall);
      }
      if (withinDateRange(outcomeCall.scheduled)) {
        outcomeCalls.push(outcomeCall);
      }
    });

    const noShowCount = uniqueBookings.filter(isCallType('noShow')).length;
    const noShowPercent = getPercent(noShowCount, uniqueBookingsCount);

    const cancellationCount = uniqueBookings.filter(isCallType('cancellation')).length;
    const cancellationPercent = getPercent(cancellationCount, uniqueBookingsCount);

    const pendingCount = uniqueBookings.filter(isCallType('pending')).length;
    const pendingPercent = getPercent(pendingCount, uniqueBookingsCount);

    const goodFitCount = uniqueBookings.filter(hasOutcome('goodFit')).length;
    const goodFitPercent = getPercent(goodFitCount, uniqueBookingsCount);

    const badFitCount = uniqueBookings.filter(hasOutcome('badFit')).length;
    const badFitPercent = getPercent(badFitCount, uniqueBookingsCount);

    const notReadyCount = uniqueBookings.filter(hasOutcome('notReady')).length;
    const notReadyPercent = getPercent(notReadyCount, uniqueBookingsCount);

    const enrolledCount = uniqueBookings.filter(hasOutcome('enrolled')).length;
    const enrolledPercent = getPercent(enrolledCount, uniqueBookingsCount);

    const depositCount = uniqueBookings.filter(hasOutcome('deposit')).length;
    const depositPercent = getPercent(depositCount, uniqueBookingsCount);

    const pastDue = uniqueBookings
      .filter(booking => moment(booking.scheduled) < moment().endOf('day'))
      .filter(
        booking =>
          !booking.assessment ||
          (!booking.assessment.completed && !booking.assessment.pending)
      ).length;

    const callsCount = countedCalls.length;
    const closingRate = getPercent(enrolledCount, callsCount);

    // console.log(
    //   'audit result:',
    //   enrolledCount === outcomeCalls.filter(hasOutcome('enrolled')).length
    //     ? 'success'
    //     : 'fail'
    // );

    return {
      key: coach.id,
      name: coach.name,
      totalBookingsCount,
      uniqueBookingsCount,
      noShowCount,
      noShowPercent,
      cancellationCount,
      cancellationPercent,
      pendingCount,
      pendingPercent,
      goodFitCount,
      goodFitPercent,
      badFitCount,
      badFitPercent,
      notReadyCount,
      notReadyPercent,
      enrolledCount,
      enrolledPercent,
      depositCount,
      depositPercent,
      pastDue,
      callsCount,
      closingRate
    };
  });

  const totals = getCoachStatsTotals(data);

  return <CoachStatsList data={data} totals={totals} />;
};

export default CoachStatsTab;
