import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import EditorUsersIndexList from '../components/EditorUsersIndexList';
import ErrorDialog from '../../shared/components/ErrorDialog';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  addLink: {
    textDecoration: 'none'
  }
}));

const EditorUsersIndex = () => {
  const classes = useStyles();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const [updated, setUpdated] = useState(0);

  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/users`;
        let { users } = await sendRequest(url);
        users =
          auth.role === 'superadmin'
            ? users
            : users.filter(user => user.role !== 'superadmin');
        setLoadedUsers(users);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth, updated]);

  return (
    <section>
      <Typography variant="h4" className={classes.title}>
        Admin Editor / Users
      </Typography>
      <ErrorDialog error={error} open={!!error} onClose={clearError} />
      {isLoading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!isLoading && loadedUsers && (
        <EditorUsersIndexList
          users={loadedUsers}
          auth={auth}
          updated={updated}
          setUpdated={setUpdated}
        />
      )}
      <Link className={classes.addLink} to={'/editor/users/create'}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />}>
          Add New User
        </Button>
      </Link>
    </section>
  );
};

export default EditorUsersIndex;
