import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import Asterisk from '../layout/RequiredAsterisk';


const useStyles = makeStyles(theme => ({
  selectContainer: {
    margin: theme.spacing(3, 0, 1, 0)
  },
  formControl: {
    margin: theme.spacing(2, 0, 0, 0),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const AssessmentSelect = (props) => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = evt => update([question.id], evt.target.value);

  return (
    <div className={classes.selectContainer}>
      <Typography variant="h6">
        {question.text} {!question.optional && <Asterisk />}
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id={question.id + '-label'}>
          {question.text}
        </InputLabel>
        <Select
          labelId={question.id + '-label'}
          id={question.id}
          value={answer}
          onChange={handleChange}
          labelWidth={labelWidth}
        >
          {question.options.map(option => {
            if (option.deprecated && answer !== option.id) {
              return null;
            }
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default AssessmentSelect;
