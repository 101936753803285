import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import EditorBookingsIndexList from '../components/EditorBookingsIndexList';
import DateRangeSelect from '../../shared/components/DateRangeSelect';

import ErrorDialog from '../../shared/components/ErrorDialog';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const EditorBookingsIndex = props => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [loadedBookings, setLoadedBookings] = useState();
  const [loadedUsers, setLoadedUsers] = useState();
  const [updated, setUpdated] = useState(0);

  const threeMonthsAgo = moment()
    .subtract(3, 'months')
    .format('YYYY-MM-DD');
  const nextWeek = moment()
    .add(7, 'days')
    .format('YYYY-MM-DD');

  const [dateRange, setDateRange] = useState({
    since: threeMonthsAgo,
    until: nextWeek
  });

  const updateDateRange = (dateParam, date) => {
    const invalidRange =
      dateParam === 'since' && moment(date) > moment(dateRange.until);
    const newDateRange = invalidRange
      ? { since: date, until: date }
      : { ...dateRange, [dateParam]: date };
    setDateRange(newDateRange);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const since = moment(dateRange.since).toISOString();
        const until = moment(dateRange.until).add(1, 'days').toISOString();
        const query = `?since=${since}&until=${until}&by=scheduled`;
        const url = `${process.env.REACT_APP_BACKEND_URL}/bookings/${query}`;
        const { bookings } = await sendRequest(url);
        setLoadedBookings(bookings);
      } catch (err) {}
    };
    fetchBookings();
  }, [sendRequest, dateRange, auth, updated]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/users`;
        let { users } = await sendRequest(url);
        users =
          auth.role === 'superadmin'
            ? users
            : users.filter(user => user.role !== 'superadmin');
        setLoadedUsers(users);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth]);

  return (
    <section>
      <Typography variant="h4" className={classes.title}>
        Admin Editor / Bookings
      </Typography>
      <ErrorDialog error={error} open={!!error} onClose={clearError} />
      <DateRangeSelect dateRange={dateRange} update={updateDateRange} />
      <Typography variant="subtitle1">
        (Searches bookings by "scheduled" date)
      </Typography>
      {isLoading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!isLoading && loadedBookings && loadedUsers && auth && (
        <EditorBookingsIndexList
          bookings={loadedBookings}
          users={loadedUsers}
          auth={auth}
          updated={updated}
          setUpdated={setUpdated}
        />
      )}
    </section>
  );
};

export default EditorBookingsIndex;
