import React from 'react';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import EditorUsersIndexItem from './EditorUsersIndexItem';

const useStyles = makeStyles(theme => ({
  usersTable: {
    margin: theme.spacing(3, 0, 4, 0)
  }
}));

const EditorUsersIndexList = props => {
  const { users, auth, updated, setUpdated } = props;
  const classes = useStyles();

  if (users.length === 0) {
    return (
      <Typography style={{ margin: '26px 0' }} variant="h6">
        No users found.
      </Typography>
    );
  }

  return (
    <TableContainer className={classes.usersTable} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Edit</TableCell>
            <TableCell align="center">Retire</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <EditorUsersIndexItem
              key={user.id}
              id={user.id}
              name={user.name}
              email={user.email}
              role={user.role}
              status={user.status}
              auth={auth}
              isAuthUser={auth.userId === user.id}
              updated={updated}
              setUpdated={setUpdated}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditorUsersIndexList;
