import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TimerIcon from '@material-ui/icons/Timer';
import SendIcon from '@material-ui/icons/Send';

import AssessmentConfig from '../data/AssessmentConfig';
import normalizeConfig from '../data/config-helpers';

import AssessmentCheckbox from './inputs/AssessmentCheckbox';
import AssessmentRadio from './inputs/AssessmentRadio';
import AssessmentSelect from './inputs/AssessmentSelect';
import AssessmentRating from './inputs/AssessmentRating';
import AssessmentTextarea from './inputs/AssessmentTextarea';
import AssessmentText from './inputs/AssessmentText';
import AssessmentDateTime from './inputs/AssessmentDateTime';
import AssessmentDate from './inputs/AssessmentDate';
import AssessmentTime from './inputs/AssessmentTime';

import AssessmentComment from './layout/AssessmentComment';
import AssessmentTitle from './layout/AssessmentTitle';
import AssessmentCustom from './layout/AssessmentCustom';

import {
  sendCoachFeedbackEmail,
  sendOnboardingEnrolledEmail,
  sendOnboardingDepositEmail,
  sendOnboardingFinancedEmail,
  sendOnboardingBadFitEmail,
  applyInfusionsoftTags,
  sendOtherOutcomeEmail
} from '../data/async-actions';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(3, 0, 5, 0)
  },
}));


const AssessmentsEditContent = (props) => {
  const { assessment } = props;
  const conf = normalizeConfig(AssessmentConfig);
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const classes = useStyles();
  const auth = useContext(AuthContext);

  const initialAnswers = assessment.answers || {};
  const defaultAnswers = conf.getDefaultAnswers();
  const initialSection = conf.getInitialSection(initialAnswers);

  const [answers, setAnswers] = useState(initialAnswers);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(initialSection);

  const updateAnswersHandler = (name, value) => {
    setAnswers({...answers, [name]: value});
  };

  const submitAnswers = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/assessments/update`;
    const method = 'PUT';
    const data = JSON.stringify({
      assessmentId: assessment.id,
      completed: conf.isCompleted(answers),
      pending: conf.isPending(answers),
      answers: conf.getRelevantAnswers(answers)
    });
    const headers = {
      'Content-Type': 'application/json',
      // Authorization: 'Bearer ' + auth.token
    };
    try {
      const result = await sendRequest(url, method, data, headers);
      console.log('Updated database!', JSON.parse(data));
      return result;
    } catch (err) {
      console.log("Couldn't update database: ", err);
    }
  };

  const next = () => {
    const nextSectionIndex = conf.getNext(currentSectionIndex, answers);
    if (nextSectionIndex !== false) {
      setCurrentSectionIndex(nextSectionIndex);
      window.scrollTo(0, 0);
    }
  };

  const prev = () => {
    const prevSectionIndex = conf.getPrev(currentSectionIndex, answers);
    if (prevSectionIndex >= 0) {
      setCurrentSectionIndex(prevSectionIndex);
      window.scrollTo(0, 0);
    }
  };

  const buttonClickHandler = (actionNames=[], navigator) => {
    const actions = {
      submitAnswers,
      applyInfusionsoftTags: () => applyInfusionsoftTags(assessment, answers, sendRequest),
      sendCoachFeedbackEmail: () => sendCoachFeedbackEmail(assessment, answers, auth.email, sendRequest),
      sendOnboardingEnrolledEmail: () => sendOnboardingEnrolledEmail(assessment, answers, conf, sendRequest),
      sendOnboardingDepositEmail: () => sendOnboardingDepositEmail(assessment, answers, conf, sendRequest),
      sendOnboardingFinancedEmail: () => sendOnboardingFinancedEmail(assessment, answers, conf, sendRequest),
      sendOnboardingBadFitEmail: () => sendOnboardingBadFitEmail(assessment, answers, conf, sendRequest),
      sendOtherOutcomeEmail: () => sendOtherOutcomeEmail(assessment, answers, sendRequest)
    };
    const navigation = {
      next,
      prev,
      goHome: () => history.push('/bookings')
      // goHome: () => {}
    };
    const asyncFns = actionNames.map(actionName => actions[actionName]());
    Promise.all(asyncFns).then(navigation[navigator]);
  };

  const componentTypeMap = {
    checkbox: (q, a, u) => <AssessmentCheckbox key={q.id} question={q} answer={a} update={u} />,
    radio   : (q, a, u) => <AssessmentRadio key={q.id} question={q} answer={a} update={u} />,
    select  : (q, a, u) => <AssessmentSelect key={q.id} question={q} answer={a} update={u} />,
    rating  : (q, a, u) => <AssessmentRating key={q.id} question={q} answer={a} update={u} />,
    textarea: (q, a, u) => <AssessmentTextarea key={q.id} question={q} answer={a} update={u} />,
    text    : (q, a, u) => <AssessmentText key={q.id} question={q} answer={a} update={u} />,
    datetime: (q, a, u) => <AssessmentDateTime key={q.id} question={q} answer={a} update={u} />,
    date    : (q, a, u) => <AssessmentDate key={q.id} question={q} answer={a} update={u} />,
    time    : (q, a, u) => <AssessmentTime key={q.id} question={q} answer={a} update={u} />,
    comment : (q, a, u) => <AssessmentComment key={q.id} question={q} answer={a} update={u} />,
    title   : (q)        => <AssessmentTitle key={q.id} question={q} />,
    custom  : (q, as, b) => <AssessmentCustom key={q.id} question={q} answers={as} booking={b} />
  };

  const currentSection = conf.sections[currentSectionIndex];
  const questions = currentSection.questions
    .filter(question => question.meetsConditions(answers))
    .map(question => {
      const type = question.type;
      if (type === 'custom') {
        return componentTypeMap[type](question, answers, assessment.booking);
      }
      const answer = answers.hasOwnProperty(question.id)
        ? answers[question.id]
        : defaultAnswers[question.id];
      return componentTypeMap[type](question, answer, updateAnswersHandler);
    });
  const completed = currentSection.isCompleted(answers);
  const pending = conf.isPending(answers);
  const isLastSection = conf.getNext(currentSectionIndex, answers) === false;

  const showPrevButton = currentSectionIndex > 0;
  const showPendingButton = completed && pending && isLastSection;
  const showSubmitButton = !showPendingButton && completed && isLastSection;
  const showNextButton = completed && !showSubmitButton && !showPendingButton;

  return (
    <div>
      {currentSection.text && (
        <React.Fragment>
          <Typography variant="h5">{currentSection.text}</Typography>
          <hr />
        </React.Fragment>
      )}
      {questions}
      {showPrevButton && (
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => buttonClickHandler([], 'prev')}
          startIcon={<NavigateBeforeIcon />}
        >
          Prev
        </Button>
      )}
      {showNextButton && (
        <Button
          className={classes.button}
          style={showPrevButton ? { marginLeft: '24px' } : {}}
          variant="contained"
          color="primary"
          endIcon={<NavigateNextIcon />}
          onClick={() => buttonClickHandler(['submitAnswers'], 'next')}
        >
          Next
        </Button>
      )}
      {showPendingButton && (
        <Button
          className={classes.button}
          style={showPrevButton ? { marginLeft: '24px' } : {}}
          variant="contained"
          color="secondary"
          endIcon={<TimerIcon />}
          onClick={() => buttonClickHandler(['submitAnswers'], 'goHome')}
        >
          Pending
        </Button>
      )}
      {showSubmitButton && (
        <Button
          className={classes.button}
          style={showPrevButton ? { marginLeft: '24px' } : {}}
          variant="contained"
          color="secondary"
          endIcon={<SendIcon />}
          onClick={() =>
            buttonClickHandler(
              [
                'submitAnswers',
                'sendOnboardingEnrolledEmail',
                'sendOnboardingDepositEmail',
                'sendOnboardingFinancedEmail',
                'sendOnboardingBadFitEmail',
                'sendCoachFeedbackEmail',
                'applyInfusionsoftTags',
                'sendOtherOutcomeEmail'
              ],
              'goHome'
            )
          }
        >
          Submit
        </Button>
      )}
    </div>
  );
};

export default AssessmentsEditContent;
