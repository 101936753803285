import React from 'react';
import Typography from "@material-ui/core/Typography";

const AssessmentTitle = props => {
    const {question} = props;

    return (
        <div>
            <Typography variant="h5">{question.text}</Typography>
            <hr />
        </div>
    );
};

export default AssessmentTitle;
