import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Asterisk from '../layout/RequiredAsterisk';


const useStyles = makeStyles(theme => ({
  textContainer: {
    margin: theme.spacing(3, 0, 1, 0)
  },
  textContainerCompressed: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  text: {
    marginTop: theme.spacing(1),
    width: '400px'
  }
}));

const AssessmentText = props => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const handleChange = evt => update(question.id, evt.target.value);

  return (
    <div
      className={
        question.compressTop
          ? classes.textContainerCompressed
          : classes.textContainer
      }
    >
      {question.text && (
        <Typography variant="h6">
          {question.text} {!question.optional && <Asterisk />}
        </Typography>
      )}
      {question.subtext && (
        <Typography variant="subtitle1">{question.subtext}</Typography>
      )}

      <TextField
        className={classes.text}
        value={answer}
        onChange={handleChange}
        label={question.label}
        variant="outlined"
      />
    </div>
  );
};

export default AssessmentText;
