import React from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';

import Asterisk from '../layout/RequiredAsterisk';


const useStyles = makeStyles(theme => ({
  checkboxContainer: {
    margin: theme.spacing(3, 0, 1, 0)
  }
}));


const AssessmentCheckbox = (props) => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const handleChange = evt => update(question.id, evt.target.checked);

  if (question.subtype === 'simple') {
    return (
      <div className={classes.checkboxContainer}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={answer}
                onChange={handleChange}
                value={question.id}
                color="primary"
              />
            }
            label={question.text}
          />
        </FormGroup>
      </div>
    );
  }

  return (
    <div className={classes.checkboxContainer}>
      <Typography variant="h6">
        {question.text} {!question.optional && <Asterisk />}
      </Typography>
      {question.subtext &&
        (question.subtext.includes('<') ? (
          ReactHtmlParser(question.subtext)
        ) : (
          <Typography variant="subtitle1">{question.subtext}</Typography>
        ))}
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={answer}
              onChange={handleChange}
              value={question.id}
              color="primary"
            />
          }
          label={question.label}
        />
      </FormGroup>
    </div>
  );
};

export default AssessmentCheckbox;
