import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';

import Asterisk from '../layout/RequiredAsterisk';


const useStyles = makeStyles(theme => ({
  textareaContainer: {
    margin: theme.spacing(3, 0, 1, 0)
  },
  textarea: {
    marginTop: theme.spacing(1),
    width: '400px'
  }
}));

const AssessmentTextarea = (props) => {
  const { question, answer, update } = props;
  const classes = useStyles();
  // const [value, setValue] = React.useState('Controlled');

  const handleChange = evt => update(question.id, evt.target.value);

  return (
    <div className={classes.textareaContainer}>
      <Typography variant="h6">
        {question.text} {!question.optional && <Asterisk />}
      </Typography>
      {question.subtext &&
        (question.subtext.includes('<') ? (
          ReactHtmlParser(question.subtext)
        ) : (
          <Typography variant="subtitle1">{question.subtext}</Typography>
        ))}

      <TextField
        className={classes.textarea}
        // label="Multiline"
        multiline
        rows="4"
        // value={answer}
        onBlur={handleChange}
        defaultValue={answer}
        variant="outlined"
      />
    </div>
  );
};

export default AssessmentTextarea;
