import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../shared/context/auth-context';


const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: '100%'
  }
}));


const EditorBookingsEditForm = props => {
  const { booking, users, sendRequest } = props;
  const auth = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();

  const editableFields = [
    'clientName',
    'clientEmail',
    'clientPhone',
    'infusionsoftId',
    'utmCampaign',
    'coachName',
    'utmCampaign'
  ];

  const initialFormData = editableFields.reduce((acc, fieldName) => {
    acc[fieldName] = booking[fieldName];
    return acc;
  }, {});

  const [formData, setFormData] = useState(initialFormData);

  const update = newFormData => {
    setFormData({
      ...formData,
      ...newFormData
    });
  };

  const submitEditRequestHandler = async evt => {
    evt.preventDefault();
    if (!window.confirm('Are you sure you want to edit this booking?')) {
      return false;
    }

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/bookings/edit`;
      const data = JSON.stringify({
        ...formData,
        id: booking.id
      });
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      };
      await sendRequest(url, 'PUT', data, headers);
      history.push('/editor/bookings');
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const updateValueHandler = evt => update({ [evt.target.name]: evt.target.value });

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="clientName"
          label="Client Name"
          name="clientName"
          value={formData.clientName}
          autoComplete="name"
          autoFocus
          onChange={updateValueHandler}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="clientEmail"
          label="Client Email"
          name="clientEmail"
          value={formData.clientEmail}
          autoComplete="email"
          onChange={updateValueHandler}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="clientPhone"
          label="Client Phone"
          name="clientPhone"
          value={formData.clientPhone}
          autoComplete="email"
          onChange={updateValueHandler}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="infusionsoftId"
          label="Infusionsoft ID"
          name="infusionsoftId"
          value={formData.infusionsoftId}
          onChange={updateValueHandler}
        />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel} id="coachName-label">
            Rep name
          </InputLabel>
          <Select
            labelId="coachName-label"
            id="coachName"
            name="coachName"
            value={formData.coachName}
            labelWidth={labelWidth}
            onChange={updateValueHandler}
          >
            {users.map(user => (
              <MenuItem key={user.id} value={user.name}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="utmCampaign"
          label="UTM Campaign"
          name="utmCampaign"
          value={formData.utmCampaign}
          autoFocus
          onChange={updateValueHandler}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={submitEditRequestHandler}
        >
          Update Booking
        </Button>
      </form>
    </>
  );
}

export default EditorBookingsEditForm;
