import React from "react";
import Container from "@material-ui/core/Container";

const Main = (props) => {
  return (
    <main style={{marginTop: '3rem'}}>
      <Container maxWidth="lg">
        {props.children}
      </Container>
    </main>
  );
};

export default Main;
