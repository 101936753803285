export default {
  title: 'Call Assessment',
  sections: [
    {
      id: 'begin',
      text: null,
      questions: [
        {
          id: 'scheduledCall',
          text: 'Scheduled Call',
          type: 'radio',
          options: [
            { id: 'spokeToClient', text: 'Spoke to Client' },
            {
              id: 'clientRescheduled',
              text: 'No call made. Client rescheduled on his own.'
            },
            { id: 'noShow', text: 'No-Show' },
            { id: 'secondNoShow', text: '2nd No-Show' },
            { id: 'cancellation', text: 'Client Cancelled' },
            { id: 'cancellationBadFit', text: 'Client Cancelled - Bad Fit' },
            {
              id: 'rescheduled',
              text:
                'Spoke to client. Client unavailable - call rescheduled (date/time known)'
            },
            {
              id: 'needsReschedule',
              text:
                'Spoke to client. Client unavailable - client will reschedule (date/time unknown)'
            },
            {
              id: 'outcomePending',
              text: 'Outcome Pending - Rep Will Follow Up',
              pending: true
            },
            { id: 'followUp', text: 'Follow-Up Call', deprecated: true },
            { id: 'followedUp', text: 'Followed Up' }
          ]
        },
        {
          conditions: { scheduledCall: 'clientRescheduled' },
          id: 'clientRescheduledComment',
          text: false,
          body:
            '<span style="color: red">(Use this only when a client reschedules on his own prior to the call happening, even if the new booking gets attributed to a different rep)</span>',
          type: 'comment',
          compressTop: true
        },
        {
          conditions: { scheduledCall: 'secondNoShow' },
          id: 'secondNoShowComment',
          text: false,
          body:
            '<span style="color: red">(This option will drop the client from our systems. If this is an exceptional circumstance, and the client may still be a good fit, please select "No-Show" instead)</span>',
          type: 'comment',
          compressTop: true
        },
        {
          conditions: { scheduledCall: 'cancellationBadFit' },
          id: 'cancellationBadFitComment',
          text: false,
          body:
            '<span style="color: red">(This will apply the "bad fit" tag in Infusionsoft, rather than the "cancelled" tag.)</span>',
          type: 'comment',
          compressTop: true
        },
        {
          conditions: { scheduledCall: 'rescheduled' },
          id: 'rescheduledComment',
          text: false,
          body:
            '<span style="color: red">(Use this only when you’ve spoken to the client, but at his request, you rescheduled)</span>',
          type: 'comment',
          compressTop: true
        },
        {
          conditions: { scheduledCall: 'needsReschedule' },
          id: 'needsRescheduleComment',
          text: false,
          body:
            '<span style="color: red">(Use this option only if you are waiting for the client to get back to you or schedule a call themselves. Do not use this option if you already know a follow up date)</span>',
          type: 'comment',
          compressTop: true
        },
        {
          conditions: { scheduledCall: 'outcomePending' },
          id: 'outcomePendingComment',
          text: false,
          body:
            '<span style="color: red">(Use this is a "partial" sales call took place, and you will be following up with the client later.)</span.',
          type: 'comment',
          compressTop: true
        },
        {
          conditions: { scheduledCall: 'followUp' },
          id: 'followUpComment',
          text: false,
          body:
            '<span style="color: red">(For rescheduled/follow-up calls. You must fill out the original assessment with the final outcome.)</span.',
          type: 'comment',
          compressTop: true
        },
        {
          conditions: { scheduledCall: 'followedUp' },
          id: 'followedUpComment',
          text: false,
          body:
            '<span style="color: red">(This marks this assessment as "no longer pending." Please ensure that the newest/most recent assessment contains the final outcome of the call.)</span.',
          type: 'comment',
          compressTop: true
        }
      ]
    },
    {
      id: 'info',
      text: null,
      questions: [
        {
          conditions: { scheduledCall: 'spokeToClient' },
          id: 'helpfulResources',
          text: 'Important note:',
          subtext:
            '',
          body: `<div>
            <ol>
            <li>Do <strong>NOT</strong> use a business name for the "First/Last Name" fields, even if it is a business card. Use the client's actual name that was used to book the call.</li>
            <li>You must enter the <strong>same email</strong> that the client used to book his/her call. Do <strong>NOT</strong> use a different email or it will be logged incorrectly in Infusionsoft.</li>
            </ol>
            <div class="custom " style="background:-moz-linear-gradient(center top , #ffffcc, #ffffcc) repeat scroll 0 0 transparent;
                           background: -webkit-gradient(linear, center top, center bottom, from(#ffffcc), to(#ffffcc));
                           background: -o-linear-gradient(top, #ffffcc 0%,#ffffcc 99%); /* Opera 11.10+ */
                           background: -ms-linear-gradient(top, #ffffcc 0%,#ffffcc 99%); /* IE10+ */
                           margin-left:0 !important;
                           margin-right:0 !important;
                           border:1px solid #bbbbbb;
                           background-color: #ffffcc;
                           width:100%;
                           color:#333333;
                           padding: 13px 15px 0px 15px;"><div class="inner-padding">If the client wants to use a different email address, do the following:</p>
            <ol>
            <li>Process the payment using the <span style="text-decoration: underline;"><strong>original</strong></span> email address.</li>
            <li>Make note of the <span style="text-decoration: underline;"><strong>new</strong></span> email address and send it to admin@vestright.com, requesting that the client's email be changed.</li>
            </ol>
            <p>Once we've received your email address update request, we will change to the new address and the client will receive all future VR correspondence at that address.</p>
            <p></div></div>
            <ol start="3">
            <li>Only the address has to match the card.</li>
            <li>Be sure to confirm the correct address (this is important because we send the client a surprise gift at the end of the course).</li>
            </ol>
            <h3>Primary Options</h3>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/VR---Land-Deals-Revealed" target="_blank" rel="noopenner noreferrer">
              Land Deals Revealed: $16K (Retail)
            </a></p>
			
			<p><a href="https://cq705.infusionsoft.com/app/orderForms/ad93e5a6-0196-4347-ade2-b0885078cb79" target="_blank" rel="noopenner noreferrer">Land Deals Revealed (Retail Split-Pay)</a><br />
			Total $16k. $8k collected upfront, with $8k auto-debited 30 days later.</p>
			
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Decisive-Discount---9k" target="_blank" rel="noopenner noreferrer">
              Decisive Discount: $9k PIF
            </a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Decisive-Discount-CVD-10k-Split-Pay---Land-Deals-Revealed" target="_blank" rel="noopenner noreferrer">
              Decisive Discount: $10k Split-Pay
            </a></p>

            <hr />
            <h3>Secondary Options</h3>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/LDR-Lock-In-DD" target="_blank" rel="noopener noopenner noreferrer">Deposit: $1k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/dbae1458-f663-4ed0-937b-ec912e63d9bb" target="_blank" rel="noopener noreferrer">Finance $12k - Down Payment $3k</a><br>
            <em>($12k total, $3000 + 5-months @ $1800/month)</em></p>
            <hr />

            <h3>Settling a Deposit</h3>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Settle-Lock-in---8k" target="_blank" rel="noopener noreferrer">Settle Deposit for $9k PIF</a><br>
            (For $1k deposit to settle $9k PIF. So one payment of $8k)</p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Settle-Lock-in---9k-Split-Pay" target="_blank" rel="noopener noreferrer">Settle Deposit for $10k Split-Pay</a><br>
            (For $1k deposit to settle $10k Split-Pay. So 2 x $4,500)</p>

            <hr />

            <h3>Multiple Cards</h3>
            <p>The below links can be used for a client who wishes to pay with multiple cards.</p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-500---Land-Deals-Revealed" target="_blank" rel="noopener noreferrer">Partial Payment $500</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/234ae84e-c8e8-4939-a7b5-ba72e4021a6a" target="_blank" rel="noopener noreferrer">Partial Payment $1k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-2k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $2k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-3k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $3k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-4k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $4k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-5k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $5k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-6k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $6k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-7k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $7k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-8k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $8k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/18230c13-d56d-472d-90d8-6f353a5298a0" target="_blank" rel="noopener noreferrer">Partial Payment $8.5k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-9k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $9k</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/Partial-Payment-10k---Land-Deals-Revealed" target="_blank" rel="noopener noopenner noreferrer">Partial Payment $10k</a></p>
            <hr />

            <h3>LAA Mentoring Upsell</h3>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/947ba234-5e3c-4699-b45e-849bf662812f" target="_blank" rel="noopener">6-month Mentorship $9k -PIF</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/0ddc8c24-3722-4679-8cd4-17f4d526afef" target="_blank" rel="noopener">6-month Mentorship $10k - Split Pay</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/1a1fcbce-42b1-445e-a5e6-fca38705856d" target="_blank" rel="noopener">12-month Mentorship $15k - PIF</a></p>
            <p><a href="https://cq705.infusionsoft.com/app/orderForms/674a9ed1-a3ec-4e60-b0d5-4364fe4deeef" target="_blank" rel="noopener">12-month Mentorship $16k - Split Pay</a></p>
			<hr />
			
			<h3>Downsells</h3>
			<p><strong>LAA</strong></p>
			
			<p><a href="https://cq705.infusionsoft.app/app/orderForms/6d6317f6-65c7-4cec-bec2-c257c05785b7?cookieUUID=73f6b3e3-0f8a-438a-9094-0253c22fbc65" target="_blank" rel="noopener">$4,995 Retail Price</a><br />
(Available Any Time)</p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/874a6bbc-1da2-4e13-91be-b92b604cb586" target="_blank" rel="noopener">$1,695 Fast Action Discount</a><br />
(MUST purchase on the call)</p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/408e16f3-50a2-4c97-9f2a-8acc500a6550" target="_blank" rel="noopener">$1,995 Decisive Discount</a><br />
(MUST purchase within 48 hours)</p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/38262db9-a54e-4d2d-b530-abd3d82a1a4a" target="_blank" rel="noopener">2 x $1,195</a><br />
(MUST purchase on the call)</p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/19286d67-8658-4903-b3d8-d0abe3c23db9" target="_blank" rel="noopener">3 x $799</a><br />
(MUST purchase on the call)</p>

<p><strong>LAA + Q&amp;A Vault</strong></p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/8d630ce1-30a1-4ac1-ae9a-d97b61da5d19" target="_blank" rel="noopener">$9,000 Retail</a></p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/16154f15-79ad-4fa9-8e8a-db1666c08264" target="_blank" rel="noopener">$4.5k Fast Action Discount</a><br />
(MUST purchase on the call)</p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/1289896d-453a-4dcb-b93c-cad677d3062c" target="_blank" rel="noopener">$5k Decisive Discount</a><br />
(MUST purchase within 48 hours)</p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/e61ecfc5-2195-4e3c-bced-9ffe584d2e99" target="_blank" rel="noopener">2 x $2,500</a><br />
(MUST purchase on the call)</p>

<p><a href="https://cq705.infusionsoft.com/app/orderForms/e4067f6d-ac26-4970-afb2-bc07af6e5a2e" target="_blank" rel="noopener">3 x $1,900</a><br />
(MUST purchase on the call)</p>

            </div>`,
          type: 'comment'
        }
      ]
    },
    // {
    //   id: 'rateSelf',
    //   text: 'Rate yourself on the following, from 1 to 5.',
    //   questions: [
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'intention',
    //       subtext: 'Your mindset level before entering the call.',
    //       type: 'radio',
    //       options: [1, 2, 3, 4, 5]
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'intentionComments',
    //       text: 'Any comments on Intention? (Optional)',
    //       type: 'textarea',
    //       optional: true
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'rapport',
    //       type: 'radio',
    //       options: [1, 2, 3, 4, 5]
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'rapportComments',
    //       text: 'Any comments on Rapport? (Optional)',
    //       type: 'textarea',
    //       optional: true
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'uncover',
    //       type: 'radio',
    //       options: [1, 2, 3, 4, 5]
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'uncoverComments',
    //       text: 'Any comments on Uncover? (Optional)',
    //       type: 'textarea',
    //       optional: true
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'sizeUp',
    //       text: 'Size Up',
    //       type: 'radio',
    //       options: [1, 2, 3, 4, 5]
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'sizeUpComments',
    //       text: 'Any comments on Size Up? (Optional)',
    //       type: 'textarea',
    //       optional: true
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'sellTheSolution',
    //       text: 'Sell the Solution',
    //       type: 'radio',
    //       options: [1, 2, 3, 4, 5]
    //     },
    //     {
    //       conditions: { scheduledCall: 'spokeToClient' },
    //       id: 'sellTheSolutionComments',
    //       text: 'Any comments on Sell the Solution? (Optional)',
    //       type: 'textarea',
    //       optional: true
    //     }
    //   ]
    // },
    {
      id: 'end',
      text: null,
      questions: [
        {
          conditions: { scheduledCall: 'spokeToClient' },
          id: 'outcome',
          text: 'Outcome',
          type: 'select',
          options: [
            { id: 'enrolled', text: 'Enrolled' },
            {
              id: 'deposit',
              text: 'Collected Deposit'
            },
            {
              id: 'financed',
              text: 'Financed'
            },
            {
              id: 'goodFit',
              text: "Good Fit - Didn't Buy"
            },
            {
              id: 'notReady',
              text: 'Not Ready - No Offer Made'
            },
            {
              id: 'badFit',
              text: 'Bad Fit - No Offer'
            },
            { id: 'other', text: 'Other' }
          ]
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'enrolled'
          },
          id: 'enrolledAmount',
          text: 'Enrolled Amount',
          subtext: '',
          type: 'radio',
          options: [
            { id: '$18k', text: '$16k' },
            { id: '$18k', text: '$16k Split Pay' },
            { id: '$14kSplit', text: '$10k Split Pay' },
            { id: '$12kPIF', text: '$9k PIF' }
          ]
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'deposit'
          },
          id: 'depositAmountCollected',
          text: 'Deposit Amount Collected',
          subtext: '',
          type: 'radio',
          options: [
            { id: '$1k', text: '$1k' }
          ]
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'deposit'
          },
          id: 'chosenEnrollmentOption',
          text: 'Chosen Enrollment Option',
          subtext: '',
          type: 'radio',
          options: [
            { id: '$18k', text: '$16k' },
            { id: '$14kSplit', text: '$10k Split' },
            { id: '$12k', text: '$9k' },
            { id: 'undecided', text: 'Undecided' }
          ]
        },
        {
          conditions: { scheduledCall: 'spokeToClient', outcome: 'other' },
          id: 'enterOtherOutcome',
          text: 'Enter Other Outcome',
          type: 'textarea'
        },
        {
          conditions: { scheduledCall: 'spokeToClient' },
          id: 'scannedNotes',
          text: 'I scanned notes',
          subtext: '(use the Google Drive link above)',
          type: 'checkbox',
          label: 'Yes'
        },
        {
          conditions: [
            { scheduledCall: ['spokeToClient', 'noShow', 'secondNoShow'] }
          ],
          id: 'uploadedAudioFile',
          text: 'I uploaded audio file (even if just a message)',
          subtext: '(also, use Google Drive link above)',
          type: 'checkbox',
          label: 'Yes'
        },
        {
          conditions: [
            { scheduledCall: 'spokeToClient', outcome: 'goodFit' },
            { scheduledCall: 'noShow' }
          ],
          id: 'setUpFollowUpSequence',
          text: 'I set up follow-up sequence (calendar reminders, etc.)',
          subtext:
            'For Follow-Up instructions on "Good Fit/Didn\'t Buy", <a target="_blank" rel="noopener noreferrer" href="https://team.vestright.com/follow-up-procedure/">SEE HERE</a>.',
          type: 'checkbox',
          label: 'Yes'
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: ['goodFit', 'notReady']
          },
          id: 'rateTheClient',
          text: 'Rate the Client',
          subtext: '(How good of a fit is this client?)',
          type: 'rating',
          max: 5
        },
        {
          conditions: { scheduledCall: 'spokeToClient', outcome: 'deposit' },
          id: 'calendaredClientFollowUp',
          text: 'I calendared a client follow-up to collect balance',
          subtext:
            '(Remember to let the client know that you will be following up on X date to collect the balance and get them started)',
          type: 'checkbox',
          label: 'Yes'
        },
        {
          conditions: { scheduledCall: 'spokeToClient', outcome: 'deposit' },
          id: 'depositInfo',
          text: 'Deposit Info',
          subtext:
            'Describe the details of this Deposit Collection. What is their start date goal? Any other pertinent details around the deposit.',
          type: 'textarea'
        },
        {
          conditions: { scheduledCall: 'rescheduled' },
          id: 'reasonForReschedule',
          text: 'Reason for Reschedule',
          type: 'textarea'
        },
        {
          conditions: { scheduledCall: 'rescheduled' },
          id: 'rescheduleDateTime',
          text: 'Reschedule Date/Time',
          type: 'datetime',
          label: ['Reschedule Date', 'Reschedule Time']
        },
        {
          conditions: {
            scheduledCall: 'rescheduled',
            rescheduleDateTime: true
          },
          id: 'rescheduleGoogleCalendarLink',
          type: 'custom',
          optional: true
        },
        {
          conditions: {
            scheduledCall: 'needsReschedule'
          },
          id: 'setUpRescheduleSequence',
          text: 'I have set up a reschedule sequence',
          subtext:
            "(If you didn't reschedule with the client on the phone, you should set up a reschedule sequence to track the client until he reschedules - email reminders, SMS, etc.)",
          type: 'checkbox',
          label: 'Yes'
        },
        {
          conditions: { scheduledCall: 'outcomePending' },
          id: 'reasonFollowUpWasScheduled',
          text: 'Reason a follow-up was scheduled',
          subtext:
            '(ex: Gave client 24 hours to think about it. Rescheduled call for tomorrow at 3pm.)',
          type: 'textarea'
        },
        {
          conditions: {
            scheduledCall: [
              'spokeToClient',
              'noShow',
              'secondNoShow',
              'cancellation',
              'rescheduled',
              'needsReschedule',
              'outcomePending'
            ]
          },
          id: 'otherComments',
          text: 'Any other comments or information to add? (Optional)',
          type: 'textarea',
          optional: true
        },
        {
          conditions: { scheduledCall: 'spokeToClient' },
          id: 'requestFeedback',
          text: 'Request feedback on this call',
          type: 'radio',
          options: ['yes', 'no']
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            requestFeedback: 'yes'
          },
          id: 'requestFeedbackAnythingSpecific',
          text: 'Anything specific you want us to critique?',
          subtext: '(Provide a timestamp if possible)',
          type: 'textarea'
        }
      ]
    },
    {
      id: 'email',
      text: null,
      questions: [
        // {
        //   conditions: {
        //     scheduledCall: 'spokeToClient',
        //     outcome: 'enrolled'
        //   },
        //   id: 'enrolledEmailTitle',
        //   text: null,
        //   type: 'comment',
        //   body: "<h5 class=\"MuiTypography-root MuiTypography-h5\">Onboarding Email: Enrolled</h5><hr />"
        // },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'enrolled'
          },
          id: 'depositEmailTitle',
          text: 'Onboarding Email: Enrolled',
          type: 'title'
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'deposit'
          },
          id: 'depositEmailTitle',
          text: 'Onboarding Email: Collected Deposit',
          type: 'title'
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'financed'
          },
          id: 'financedEmailTitle',
          text: 'Onboarding Email: Financed',
          type: 'title'
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: ['enrolled', 'deposit', 'financed']
          },
          id: 'confirmClientFirstName',
          text: "Please confirm the client's first and last name.",
          type: 'text',
          label: 'First name'
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: ['enrolled', 'deposit', 'financed']
          },
          id: 'confirmClientLastName',
          text: null,
          type: 'text',
          label: 'Last name',
          compressTop: true
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'deposit',
          },
          id: 'startDate',
          text: 'Fill in the blank - Start date',
          subtext: `<div>
            <p>[Enrollment Coach] let us know that you will be completing the enrollment and getting started with us <strong>_______________</strong>.</p>
            <p>Examples:</p>
            <ul>
              <li>"in January"</li>
              <li>"on March 2nd"</li>
              <li>"in two weeks"</li>
              <li>"in the next couple months"</li>
            </ul>
            <p><strong>NOTE</strong>: Do not capitalize the first letter of your sentence, and do not put a period at the end.</p>
          </div>`,
          type: 'textarea'
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: 'deposit',
          },
          id: 'remainingBalance',
          text: 'Fill in the blank - Remaining Balance',
          subtext: `<div>
            <p>Once we've received the remaining balance of $________________, we will get you access to the Members Site and get you started on your training.</p>
            <p>Example:</p>
            <ul>
              <li>"11k"</li>
              <li>"10K"</li>
            </ul>
            <p><strong>NOTE</strong>: Do not use a "$" in front of your number.</p>
          </div>`,
          type: 'textarea'
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: ['enrolled', 'deposit', 'financed']
          },
          id: 'canEmailBeSentImmediately',
          text: "Is there any reason this email can't be sent out immediately?",
          type: 'radio',
          options: [
            { id: 'goAhead', text: 'Go ahead and sent it out right away.' },
            { id: 'holdOff', text: 'Please hold off on sending this.' }
          ]
        },
        {
          conditions: {
            scheduledCall: 'spokeToClient',
            outcome: ['enrolled', 'deposit', 'financed'],
            canEmailBeSentImmediately: 'holdOff'
          },
          id: 'reasonForHoldingOff',
          text: 'Reason for holding off on sending the Onboarding Email',
          subtext:
            'Describe the situation. When would you like the Onboarding Email sent? What about the Member Agreement? Etc.',
          type: 'textarea'
        }
      ]
    }
  ]
};