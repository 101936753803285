import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  totalsRow: {
    background: theme.palette.primary.main
  },
  totalsCell: {
    color: 'white'
  }
}));

const addCommas = str => String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const CoachStatsItem = props => {
  const { totals } = props;
  const classes = useStyles();

  const tableCellClass = totals ? classes.totalsCell : '';

  return (
    <TableRow className={totals ? classes.totalsRow : ''}>
      <TableCell className={tableCellClass} component="th" scope="row">
        {props.name}
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {addCommas(props.totalBookingsCount)}
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {addCommas(props.uniqueBookingsCount)}
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.noShowPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.noShowCount)})</em>
        </small>
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.cancellationPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.cancellationCount)})</em>
        </small>
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.pendingPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.pendingCount)})</em>
        </small>
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.goodFitPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.goodFitCount)})</em>
        </small>
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.badFitPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.badFitCount)})</em>
        </small>
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.notReadyPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.notReadyCount)})</em>
        </small>
      </TableCell>

      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.enrolledPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.enrolledCount)})</em>
        </small>
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.depositPercent).toFixed(2)}%
        <small>
          <em>({addCommas(props.depositCount)})</em>
        </small>
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {props.pastDue}
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {addCommas(props.callsCount)}
      </TableCell>
      <TableCell className={tableCellClass} align="center" scope="row">
        {Number(props.closingRate).toFixed(2)}%
      </TableCell>
    </TableRow>
  );
};

export default CoachStatsItem;
