import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Asterisk from '../layout/RequiredAsterisk';


const useStyles = makeStyles(theme => ({
  ratingContainer: {
    margin: theme.spacing(3, 0, 1, 0)
  },
  rating: {
    marginLeft: 0,
    paddingLeft: 0
  }
}));

const AssessmentRating = (props) => {
  const { question, answer, update } = props;
  const classes = useStyles();

  const changeHandler = (event, newValue) => update(question.id, newValue);

  return (
    <div className={classes.ratingContainer}>
      <Typography variant="h6">
        {question.text} {!question.optional && <Asterisk />}
      </Typography>
      <Box
        className={classes.rating}
        component="fieldset"
        mb={3}
        borderColor="transparent"
      >
        {question.subtext && (
          <Typography component="legend">{question.subtext}</Typography>
        )}
        <Rating
          name={question.id}
          value={answer}
          onChange={changeHandler}
          max={question.max}
        />
      </Box>
    </div>
  );
};

export default AssessmentRating;
