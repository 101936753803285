import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [authData, setAuthData] = useState({
    token: false,
    userId: false,
    name: false,
    email: false,
    role: false,
    bookingPageUrl: false
  });

  const login = useCallback((loginAuthData, expirationDate) => {

    setAuthData(loginAuthData);

    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 7);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        ...loginAuthData,
        expiration: tokenExpirationDate.toISOString()
      })
    );
  }, []);

  const logout = useCallback(() => {
    setAuthData({
      token: null,
      userId: null,
      name: null,
      email: null,
      role: null,
      bookingPageUrl: null
    });
    setTokenExpirationDate(null);
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (authData.token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [authData.token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(storedData, new Date(storedData.expiration));
    }
  }, [login]);

  return {
    login,
    logout,
    token: authData.token,
    userId: authData.userId,
    name: authData.name,
    email: authData.email,
    role: authData.role,
    bookingPageUrl: authData.bookingPageUrl
  };
};