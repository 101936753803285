import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as Redux } from 'react-redux';

import './index.css';
import App from './App';
import store from './redux/store';


ReactDOM.render(
  <Redux store={store}>
    <App />
  </Redux>,
  document.getElementById('root')
);

// const mapStateToProps = state => {
//   return {
//     ctr: state.counter
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     onIncrementCounter: () => dispatch({ type: 'INCREMENT' })
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Counter);
