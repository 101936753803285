import React, { useReducer, useEffect } from 'react';

import { validate } from '../../util/validators';

import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      };
    }
    default:
      return state;
  }
};

const Input = props => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isTouched: false,
    isValid: props.initialValid || false
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = event => {
    dispatch({
      type: 'CHANGE',
      val: event.target.value,
      validators: props.validators
    });
  };

  const touchHandler = () => {
    dispatch({
      type: 'TOUCH'
    });
  };


  const element =
    props.element === 'input' ? (
      <TextField
        id={props.id}
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        error={!inputState.isValid && inputState.isTouched && true}
        helperText={
          !inputState.isValid && inputState.isTouched && props.errorText
        }
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       {props.icon}
        //     </InputAdornment>
        //   )
        // }}
      />
    ) : (
      <TextField
        id={props.id}
        rows={props.rows || 3}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        error={!inputState.isValid && inputState.isTouched && true}
        helperText={
          !inputState.isValid && inputState.isTouched && props.errorText
        }
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       {props.icon}
        //     </InputAdornment>
        //   )
        // }}
      />
    );

  return (
    <div
      className={`form-control ${!inputState.isValid &&
        inputState.isTouched &&
        'form-control--invalid'}`}
    >
      {element}
    </div>
  );
};

export default Input;
