import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

import { useHttpClient } from '../../shared/hooks/http-hook';
// import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  startBtn: {
    margin: '-10px 0'
  },
  resumeBtn: {
    color: 'gray',
    margin: '-10px 0'
  },
  resumeBtnLink: {
    textDecoration: 'none'
  },
  pastScheduledDate: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  }
}));

const statusColors = {
  pastDue: { color: 'red', fontWeight: 'bold' },
  pending: { color: 'blue' },
  waiting: {},
  complete: {}
};


const BookingsIndexItem = props => {
  // const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const classes = useStyles();

  const status = props.assessment
    ? props.assessment.completed
      ? 'completed'
      : 'started'
    : 'notStarted';

  const StartButton = () => (
    <Button
      className={classes.startBtn}
      color="primary"
      onClick={startAssessmentHandler}
      startIcon={<AssessmentIcon />}
      fullWidth
    >
      Start
    </Button>
  );
  const ResumeButton = () => (
    <Link
      className={classes.resumeBtnLink}
      to={'/assessments/edit/' + (props.assessment ? props.assessment.id : '')}
    >
      <Button
        className={classes.resumeBtn}
        startIcon={<EditIcon />}
        fullWidth
      >
        Resume
      </Button>
    </Link>
  );

  const button = {
    notStarted: <StartButton />,
    started: <ResumeButton />,
    completed: <Icon>check</Icon>
  }[status];

  const startAssessmentHandler = async evt => {
    evt.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/assessments/create`;
      const content = JSON.stringify({ bookingId: props.id });
      const params = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer ' + auth.token
      };
      const response = await sendRequest(url, 'POST', content, params);
      history.push('/assessments/edit/' + response.assessment.id);
    } catch (err) {}
  };

  return (
    <TableRow key={props.trackingId}>
      <TableCell component="th" scope="row">
        {props.trackingId}
      </TableCell>
      <TableCell align="right">
        {moment(props.created).format('M/DD/YY')}
      </TableCell>
      <TableCell align="right">
        <span style={statusColors[props.assessmentStatus]}>
          {moment(props.scheduled).format('M/DD/YY @ LT')}
        </span>
      </TableCell>
      <TableCell align="right">{props.clientName}</TableCell>
      <TableCell align="right">{props.clientEmail}</TableCell>
      <TableCell align="right">{props.clientPhone}</TableCell>
      <TableCell align="right">{button}</TableCell>
    </TableRow>
  );
};

export default BookingsIndexItem;
