import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../shared/context/auth-context';


const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: '100%'
  }
}));


const EditorUsersEditForm = props => {
  const { user, sendRequest } = props;
  const auth = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();

  const initialFormData = { ...user };
  Object.keys(initialFormData).forEach(name => {
    if (name.startsWith('_')) {
      delete initialFormData[name];
    }
  });

  const [formData, setFormData] = useState(initialFormData);

  const update = newFormData => {
    setFormData({
      ...formData,
      ...newFormData
    });
  };

  const submitEditRequestHandler = async evt => {
    evt.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/users/update`;
      const outgoingFormData = { ...formData };
      Object.keys(outgoingFormData).forEach(name => {
        if (outgoingFormData[name].trim() === '') {
          delete outgoingFormData[name];
        }
      });
      const data = JSON.stringify({
        ...outgoingFormData,
        userId: user.id
      });
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      };
      await sendRequest(url, 'PUT', data, headers);
      history.push('/editor/users');
    } catch (err) {}
  };

  const updateValueHandler = evt => update({ [evt.target.name]: evt.target.value });

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <form className={classes.form} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="name"
        label="Name"
        name="name"
        value={formData.name}
        autoComplete="name"
        autoFocus
        onChange={updateValueHandler}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        value={formData.email}
        autoComplete="email"
        onChange={updateValueHandler}
      />
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="role-label">
          Role
        </InputLabel>
        <Select
          labelId="role-label"
          id="role"
          name="role"
          value={formData.role}
          labelWidth={labelWidth}
          onChange={updateValueHandler}
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="coach">Rep</MenuItem>
        </Select>
      </FormControl>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="bookingPageUrl"
        value={formData.bookingPageUrl}
        label="Direct Rep Booking Page URL"
        id="bookingPageUrl"
        onChange={updateValueHandler}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="password"
        label="New password (leave blank if unchanged)"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={updateValueHandler}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={submitEditRequestHandler}
      >
        Update User
      </Button>
    </form>
  );
}

export default EditorUsersEditForm;
