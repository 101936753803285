import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

import { useHttpClient } from '../../shared/hooks/http-hook';
// import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  startBtn: {
    margin: '-10px 0'
  },
  resumeBtn: {
    color: 'gray',
    margin: '-10px 0'
  },
  editBtn: {
    color: 'gray',
    margin: '-10px 0'
  },
  deleteBtn: {
    // color: 'gray',
    margin: '-10px 0'
  },
  btnLink: {
    textDecoration: 'none'
  },
  pastScheduledDate: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  }
}));

const EditorBookingsIndexItem = props => {
  // const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  // const history = useHistory();
  const classes = useStyles();
  const { auth, updated, setUpdated } = props;
    const infBaseUrl = 'https://cq705.infusionsoft.com/';
    const clientEmail = window.encodeURIComponent(props.clientEmail);
    const infusionsoftLink = props.infusionsoftId
        ? `${infBaseUrl}Contact/manageContact.jsp?view=edit&ID=${props.infusionsoftId}&Sidebar=true`
        : `${infBaseUrl}app/searchResults/searchResults?searchTerm=${clientEmail}`;
    const clientName = (
        <a href={infusionsoftLink} target="_blank" rel="noopener noreferrer">
            {props.clientName}
        </a>
    );

  const sendUpdate = async (urlPath, method, data, messageType) => {
    const confMessage = `Are you sure you want to ${messageType} booking '${props.trackingId}'?`;
    if (!window.confirm(confMessage)) {
      return false;
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/${urlPath}`;
      const content = data ? JSON.stringify(data) : null;
      const params = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      };
      await sendRequest(url, method, content, params);
      setUpdated(updated + 1);
    } catch (err) {
      console.log('Error submitting request: ', err);
    }
  };

  const deleteBookingHandler = async evt =>
    sendUpdate(`bookings/${props.id}`, 'DELETE', null, 'delete');

  const EditButton = () => (
    <Link className={classes.btnLink} to={'/editor/bookings/edit/' + props.id}>
      <Button
        className={classes.editBtn}
        color="primary"
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
    </Link>
  );

  const DeleteButton = () => (
    <IconButton
      className={classes.deleteBtn}
      color="secondary"
      onClick={deleteBookingHandler}
    >
      <DeleteIcon />
    </IconButton>
  );

  return (
    <TableRow key={props.trackingId}>
      <TableCell component="th" scope="row">
        {props.trackingId}
      </TableCell>
      <TableCell align="right">
        {moment(props.created).format('M/DD/YY')}
      </TableCell>
      <TableCell align="right">
        {moment(props.scheduled).format('M/DD/YY @ LT')}
      </TableCell>
      <TableCell align="right">{clientName}</TableCell>
      <TableCell align="right">{props.clientEmail}</TableCell>
      <TableCell align="right">{props.coachName}</TableCell>
      <TableCell align="center">
        <EditButton />
      </TableCell>
      <TableCell align="center">
        <DeleteButton />
      </TableCell>
    </TableRow>
  );
};

export default EditorBookingsIndexItem;
