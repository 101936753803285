import _ from 'lodash';

const normalizeConfig = config => {
  const conf = JSON.parse(JSON.stringify(config));
  conf.sections = Array.isArray(conf.sections)
    ? conf.sections.map(section => normalizeSection(section))
    : null;

  conf.getQuestions = function () {
    return _.flatten(this.sections.map(sect => sect.questions));
  };
  conf.getInitialSection = function (answers) {
    const lastIncomplete = this.sections.findIndex(section => {
      return !section.isCompleted(answers);
    });
    const lastRelevant = this.getLastRelevant(answers);
    return lastIncomplete < 0 ? lastRelevant : lastIncomplete;
  };
  conf.getDefaultAnswers = function () {
    return this.getQuestions().reduce((acc, q) => {
      acc[q.id] = {
        checkbox: false,
        radio: '', // q.options && q.options[0].id
        select: '', // q.options && q.options[0].id
        rating: 0, // Number(q.options && Math.ceil(q.options.length / 2)) || 3,
        text: '',
        textarea: '',
        datetime: null, // new Date(),
        date: '', // moment().format('YYYY-MM-DD'),
        time: '' // '07:30'
      }[q.type];
      return acc;
    }, {});
  };
  conf.isCompleted = function (answers) {
    return (
      this.sections.every(section => section.isCompleted(answers)) &&
      !this.isPending(answers)
    );
  };
  conf.isPending = function (answers) {
    return this.getQuestions()
      .filter(
        question => question.type === 'radio' || question.type === 'select'
      )
      .some(question => {
        return question.options.some(
          option => answers[question.id] === option.id && option.pending
        );
      });
  };
  conf.getRelevantSections = function (answers) {
    return this.sections.filter(section => section.meetsConditions(answers));
  };
  conf.getRelevantAnswers = function (answers) {
    const questions = this.getQuestions();
    return Object.keys(answers)
      .filter(answerId => {
        const question = questions.find(q => q.id === answerId);
        if (!question) {
          console.warn("Invalid question id in 'answers' state: ", answerId);
          return false;
        }
        return question.meetsConditions(answers);
      })
      .reduce((acc, answerId) => {
        acc[answerId] = answers[answerId];
        return acc;
      }, {});
  };

  conf.getLastRelevant = function (answers) {
    let index = 0;
    (function getLast(i, that) {
      const newIndex = that.getNext(i, answers);
      if (newIndex !== false) {
        index = newIndex;
        return getLast(newIndex, that);
      }
    }(index, this));
    return index;
  };

  conf.getNext = function (currentSection, answers) {
    const nextSection = currentSection + 1;
    if (nextSection > this.sections.length - 1) {
      return false;
    }
    const sect = this.sections[nextSection];
    const relevant = sect && sect.meetsConditions(answers);
    return !relevant
      ? this.getNext(nextSection, answers)
      : nextSection;
  };

  conf.getPrev = function (currentSection, answers) {
    const prevSection = currentSection - 1;
    if (prevSection < 0) {
      return false;
    }
    const sect = this.sections[prevSection];
    const relevant = sect && sect.meetsConditions(answers);
    return !relevant
      ? this.getPrev(prevSection, answers)
      : prevSection;
  };

  return conf;
};

const normalizeSection = section => {
  section.isCompleted = function (answers) {
    return this.questions
      .filter(question => question.meetsConditions(answers)) // 'relevant'
      .filter(question => !question.optional) // required
      .every(q => answers.hasOwnProperty(q.id) && !!answers[q.id]); // has an answer
  };
  section.meetsConditions = function (answers) { // (i.e. 'at least one question meets conditions to be visible')
    return this.questions.some(question => question.meetsConditions(answers));
  };
  section.questions = Array.isArray(section.questions)
    ? section.questions.map(question => normalizeQuestion(question))
    : null;
  return section;
};

const normalizeQuestion = question => {
  question.text = question.text === undefined ? _.startCase(question.id) : question.text;
  question.options = Array.isArray(question.options)
    ? question.options.map(option => normalizeOption(option))
    : null;
  if (question.type === 'comment' || question.type === 'title') {
    question.optional = true
  }

  question.meetsConditions = function (answers) {
    const { conditions } = this;
    const checkConditionSet = conds => {
      return Object.keys(conds).every(key => {
        const allowed = conds[key];
        if (allowed === true) { // true means 'any answer suffices'
          return !!answers[key];
        }
        return _.isArray(allowed)
          ? allowed.some(v => v === answers[key])
          : allowed === answers[key];
      });
    };
    if (_.isPlainObject(conditions)) {
      return checkConditionSet(conditions);
    }
    if (_.isArray(conditions)) {
      return conditions.some(conds => {
        return checkConditionSet(conds);
      });
    }
    return true;
  };

  return question;
};

const normalizeOption = option => {
  if (!_.isPlainObject(option)) {
    return {
      id: String(option),
      text: _.startCase(String(option))
    };
  }
  option.text = option.text || _.startCase(option.id);
  return option;
};


export default normalizeConfig;
