import React from 'react';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import CoachStatsItem from './CoachStatsItem';

const CoachStatsList = props => {

  const { data, totals } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Rep Name</TableCell>
            <TableCell align="center">Bookings</TableCell>
            <TableCell align="center">Uniques</TableCell>
            <TableCell align="center">No-Shows</TableCell>
            <TableCell align="center">Cancels</TableCell>
            <TableCell align="center">Pen.</TableCell>
            <TableCell align="center">Good Fit</TableCell>
            <TableCell align="center">Bad Fit</TableCell>
            <TableCell align="center">Not Ready</TableCell>
            <TableCell align="center">Enrollments</TableCell>
            <TableCell align="center">Deposits</TableCell>
            <TableCell align="center">Past Due</TableCell>
            <TableCell align="center">Calls</TableCell>
            <TableCell align="center">Closing Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CoachStatsItem totals {...totals} />
          {data.map(coach => (
            <CoachStatsItem {...coach} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CoachStatsList;

