import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import CampaignStatsTab from '../components/CampaignStatsTab';
import BookingStatsTab from '../components/BookingStatsTab';
import CoachStatsTab from '../components/CoachStatsTab';

import DateRangeSelect from '../../shared/components/DateRangeSelect';
import ErrorDialog from '../../shared/components/ErrorDialog';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const StatisticsIndex = props => {

  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [loadedCampaigns, setLoadedCampaigns] = useState([]);
  const [loadedBookings, setLoadedBookings] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const yesterday = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  const [dateRange, setDateRange] = useState({
    since: yesterday,
    until: yesterday
  });

  const [view, setView] = useState(0);


  const updateDateRange = (dateParam, date) => {
    const invalidRange = dateParam === 'since' && moment(date) > moment(dateRange.until);
    const newDateRange = invalidRange
      ? { since: date, until: date }
      : { ...dateRange, [dateParam]: date };
    setDateRange(newDateRange);
  };

  const updateView = (event, clickedTab) => {
    setView(clickedTab);
  };


  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const query = `?since=${dateRange.since}&until=${dateRange.until}`;
        const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/` + query;
        const { campaigns } = await sendRequest(url);
        setLoadedCampaigns(campaigns);
      } catch (err) {
        console.log('Error at fetchCampaigns(): ', err);
      }
    };
    fetchCampaigns();
  }, [sendRequest, dateRange]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/bookings`;
        const { bookings } = await sendRequest(url);
        setLoadedBookings(bookings);
      } catch (err) {
        console.log('Error at fetchBookings(): ', err);
      }
    };
    fetchBookings();
  }, [sendRequest]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/users`;
        let { users } = await sendRequest(url);
        users =
          auth.role === 'superadmin'
            ? users
            : users.filter(user => user.role !== 'superadmin');
        setLoadedUsers(users);
      } catch (err) {
        console.log('Error at fetchUsers(): ', err);
      }
    };
    fetchUsers();
  }, [sendRequest, auth]);


  return (
    <section>
      <Typography variant="h4" className={classes.title}>
        Statistics
      </Typography>
      <ErrorDialog error={error} open={!!error} onClose={clearError} />
      <DateRangeSelect dateRange={dateRange} update={updateDateRange} />
      <Typography variant="subtitle1">
        (Searches bookings by "created" date)
      </Typography>
      <Paper className={classes.root}>
        <Tabs
          value={view}
          onChange={updateView}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Campaigns" />
          <Tab label="Bookings" />
          <Tab label="Reps" />
        </Tabs>
      </Paper>
      {isLoading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!isLoading && loadedCampaigns && loadedBookings && view === 0 && (
        <CampaignStatsTab
          campaigns={loadedCampaigns}
          bookings={loadedBookings}
          dateRange={dateRange}
        />
      )}
      {!isLoading && loadedBookings && view === 1 && (
        <BookingStatsTab bookings={loadedBookings} dateRange={dateRange} />
      )}
      {!isLoading && loadedBookings && loadedUsers && view === 2 && (
        <CoachStatsTab
          users={loadedUsers}
          bookings={loadedBookings}
          dateRange={dateRange}
        />
      )}
    </section>
  );
};

export default StatisticsIndex;
