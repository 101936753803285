import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';

import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import Header from './shared/components/Header/Header';
import Main from "./shared/components/Main/Main";

import EditorUsersIndex from './editor/pages/EditorUsersIndex';
import EditorUsersCreate from './editor/pages/EditorUsersCreate';
import EditorUsersEdit from './editor/pages/EditorUsersEdit';
import EditorBookingsIndex from './editor/pages/EditorBookingsIndex';
import EditorBookingsEdit from './editor/pages/EditorBookingsEdit';
import EditorAssessmentsIndex from './editor/pages/EditorAssessmentsIndex';
// import EditorBookingsEdit from './editor/pages/EditorBookingsEdit';

// import UsersIndex from './users/pages/UsersIndex';
// import UsersCreate from './users/pages/UsersCreate';
// import UsersEdit from './users/pages/UsersEdit';

import UsersLogin from './users/pages/UsersLogin';
import UsersSignin from './users/pages/UsersSignin';
import AssessmentsEdit from "./assessments/pages/AssessmentsEdit";
import AssessmentsView from "./assessments/pages/AssessmentsView";
import AssessmentsIndex from "./assessments/pages/AssessmentsIndex";
import BookingsIndex from "./bookings/pages/BookingsIndex";
import StatisticsIndex from "./statistics/pages/StatisticsIndex";

import './App.css';


const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink
  },
  status: {
    danger: 'orange'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1480,
      xl: 1920
    }
  }
});


const App = () => {
  const {
    token,
    userId,
    email,
    name,
    role,
    bookingPageUrl,
    login,
    logout
  } = useAuth();

  let routes;

  const adminRoutes = [
    <Route key="users-index" path="/editor/users" exact>
      <EditorUsersIndex />
    </Route>,
    <Route key="users-create" path="/editor/users/create" exact>
      <EditorUsersCreate />
    </Route>,
    <Route key="users-edit" path="/editor/users/edit/:userId" exact>
      <EditorUsersEdit />
    </Route>,
    <Route key="bookings-index" path="/editor/bookings" exact>
      <EditorBookingsIndex />
    </Route>,
    <Route key="bookings-edit" path="/editor/bookings/edit/:bookingId" exact>
      <EditorBookingsEdit />
    </Route>,
    <Route key="assessments-index" path="/editor/assessments" exact>
      <EditorAssessmentsIndex />
    </Route>,
    // <Route path="/users" exact>
    //   <UsersIndex />
    // </Route>,
    // <Route path="/users/create" exact>
    //   <UsersCreate />
    // </Route>,
    // <Route path="/users/edit/:userId" exact>
    //   <UsersEdit />
    // </Route>,
    <Route key="statistics-index" path="/statistics" exact>
      <StatisticsIndex />
    </Route>
  ];

  if (token) {
    routes = (
      <Switch>
        {role.includes('admin') && adminRoutes}
        <Route path="/bookings" exact>
          <BookingsIndex />
        </Route>
        <Route path="/assessments" exact>
          <AssessmentsIndex />
        </Route>
        <Route path="/assessments/edit/:assessmentId" exact>
          <AssessmentsEdit />
        </Route>
        <Route path="/assessments/view/:assessmentId" exact>
          <AssessmentsView />
        </Route>
        <Redirect from="/" to="/bookings" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/users/signin" exact>
          <UsersSignin />
        </Route>
        <Route path="/users/login" exact>
          <UsersLogin />
        </Route>
        <Route path="/">
          <UsersLogin />
        </Route>
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        userId,
        email,
        name,
        role,
        bookingPageUrl,
        login,
        logout
      }}
    >
      <Router>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Header />
          <Main>{routes}</Main>
        </ThemeProvider>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
